// App.js

import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import NavBar from "./components/NavBar/NavBar";
import HeroSection from "./components/HeroSection/HeroSection";
import MissionSection from "./components/MissionSection/MissionSection";
import DynamicSection from "./components/DynamicSection/DynamicSection";
import MonthlySchedule from "./components/MonthlySchedule/MonthlySchedule";
import AboutUs from "./components/AboutUs/AboutUs";
import Services from "./components/Services/Services";
import Footer from "./components/Footer/Footer";
import Contact from "./components/Contact/Contact";
import Login from "./components/Login/Login";
import Donate from "./components/Donate/Donate";
import Policies from "./components/Policies/Policies";
import UserProfile from "./components/UserProfile/UserProfile";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import Register from "./components/Register/Register";
import RegistrationSuccess from "./components/RegistrationSuccess/RegistrationSuccess";
import ParticipationForm from "./components/ParticipationForm/ParticipationForm";
import ClassScheduler from "./components/ClassScheduler/ClassScheduler";
import { CartProvider } from "./components/CartContext/CartContext";
import CartPage from "./components/CartPage/CartPage";
import StripeCheckoutPage from "./components/CartPage/StripeCheckoutPage";
import { ClassesProvider } from "./components/ClassesContext/ClassesContext";
import ClassLists from "./components/UserProfile/ClassLists";
import MyClasses from "./components/UserProfile/MyClasses";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import GoogleAuthTest from "./components/Login/GoogleAuthTest";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthProvider } from "./components/context/AuthContext";
import UserList from "./components/UserProfile/UserList";
import Invoicing from "./components/Invoicing/Invoicing";
import DonationSection from "./components/DonateSection/DonationSection";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

import { useIdleTimer } from "react-idle-timer";
import EditClasses from "./components/EditClasses/EditClasses";

function App() {
  const clientId =
    "711154044658-25o8imctgqmdri9drjqktehqairc2sa6.apps.googleusercontent.com";
  const [participationWaiverData, setParticipationWaiverData] = useState([]);
  const [filteredParticipationData, setFilteredParticipationData] = useState(
    []
  );
  const location = useLocation();
  const navigate = useNavigate(); // Initialize useNavigate
  const [currentPage, setCurrentPage] = useState(() => {
    const storedPage = localStorage.getItem("currentPage");
    return storedPage || "home";
  });
  const [events, setEvents] = useState([]);
  const [checkoutData, setCheckoutData] = useState({
    cartItems: [],
    totalAmount: 0
  });
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedEventDetails, setSelectedEventDetails] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userRole, setUserRole] = useState(null);

  // Reference to store the timeout ID
  const idleTimerRef = useRef(null);

  // Function to handle logout
  const handleLogout = () => {
    setCurrentPage("home");
    localStorage.removeItem("token");
    localStorage.setItem("currentPage", "home");
    setUserId(null);
    setUserRole(null);
    navigate("/"); // Redirect to home page
  };

  // Function to check token validity
  const checkTokenValidity = () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000; // in seconds
        if (decoded.exp < currentTime) {
          handleLogout();
        }
      } catch (error) {
        console.error("Invalid token:", error);
        handleLogout();
      }
    }
  };

  // Initialize idle timer using react-idle-timer
  useIdleTimer({
    timeout: 15 * 60 * 1000, // 15 minutes in milliseconds
    onIdle: handleLogout,
    debounce: 500
  });

  // Periodically check token validity
  useEffect(() => {
    const interval = setInterval(() => {
      checkTokenValidity();
    }, 5 * 60 * 1000); // every 5 minutes

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchParticipationWaivers = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error(
          "No token found, aborting fetch for participation waivers."
        );
        return;
      }

      try {
        const hardcodedBaseURL = "https://www.ptemployment.org";
        const response = await axios.get(
          `${hardcodedBaseURL}/api/participation-waivers`,
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        setParticipationWaiverData(response.data);
      } catch (error) {
        // If unauthorized, log out the user
        if (error.response && error.response.status === 401) {
          handleLogout();
        }
      }
    };

    // console.log(`Navigated to: ${location.pathname}`);
    fetchParticipationWaivers();
    // No need for manual timer reset as react-idle-timer handles it
  }, [location.pathname]);

  useEffect(() => {
    switch (location.pathname) {
      case "/userProfile":
        break;
      case "/adminDashboard":
        break;
      default:
    }
    // No need for manual timer reset
  }, [location]);

  useEffect(() => {
    if (selectedEventId != null) {
      const newFilteredData = participationWaiverData.filter(
        (waiver) => waiver?.event_id?.toString() === selectedEventId.toString()
      );
      setFilteredParticipationData(newFilteredData);
    } else {
      setFilteredParticipationData([]);
    }
    // No need for manual timer reset
  }, [selectedEventId, participationWaiverData]);

  const aboutUsRefs = {
    board: useRef(null),
    partners: useRef(null),
    sponsors: useRef(null)
  };

  const handleCheckout = (cartItems, totalAmount) => {
    setCheckoutData({ cartItems, totalAmount, userId });
    setCurrentPage("stripeCheckout");
    // No need for manual timer reset
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    } else {
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
      } catch (error) {}
    } else {
      console.log("No token found in localStorage.");
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUserRole(decoded.role);
        setUserId(decoded.user_id);
      } catch (error) {
        console.error("Invalid token:", error);
        handleLogout();
      }
    }
    // No need for manual timer reset
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    fetchEvents();
    const handlePageChange = () => {
      const storedPage = localStorage.getItem("currentPage");
      if (storedPage) {
        setCurrentPage(storedPage);
      }
    };

    window.addEventListener("storage", handlePageChange);

    return () => window.removeEventListener("storage", handlePageChange);
  }, []);

  const fetchEvents = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = token
        ? { headers: { Authorization: `Bearer ${token}` } }
        : {};

      const baseURL = process.env.REACT_APP_API_URL || "http://localhost:3000";

      const response = await axios.get(
        `https://www.ptemployment.org/api/class-schedules`,
        config
      );

      const formattedEvents = Array.isArray(response.data)
        ? response.data.map((event) => ({
            ...event,
            start: new Date(event.start_time),
            end: new Date(event.end_time)
          }))
        : [];

      setEvents(formattedEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
      // If unauthorized, log out the user
      if (error.response && error.response.status === 401) {
        handleLogout();
      }
    }
  };

  // **Renamed 'navigate' to 'handleNavigate' to avoid confusion with useNavigate**
  const handleNavigate = (page, eventId = null) => {
    setCurrentPage(page);
    setSelectedEventId(eventId);

    if (Array.isArray(events) && eventId !== null) {
      const eventDetails = events.find(
        (event) => event.id.toString() === eventId.toString()
      );

      if (eventDetails) {
        setSelectedEventDetails({
          title: eventDetails.title,
          date: new Date(eventDetails.start_time).toLocaleDateString(),
          time: new Date(eventDetails.start_time).toLocaleTimeString()
        });
      } else {
        console.error(`Event with ID ${eventId} not found.`);
        setSelectedEventDetails(null);
      }
    } else {
      setSelectedEventDetails(null);
    }

    localStorage.setItem("currentPage", page);
    // No need for manual timer reset
  };

  // Remove existing manual event listeners as react-idle-timer handles it
  // If you have other reasons to keep them, ensure they don't conflict

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <AuthProvider>
        <CartProvider>
          <ClassesProvider>
            <div className="App">
              <NavBar />
              <Routes>
                {/* Your existing routes */}
                <Route
                  path="/"
                  element={
                    <>
                      <HeroSection />
                      <MissionSection />
                      <DonationSection />
                      <DynamicSection />
                    </>
                  }
                />
                <Route
                  path="/monthlySchedule"
                  element={<MonthlySchedule events={events} />}
                />
                <Route path="/aboutUs" element={<AboutUs />} />
                <Route path="/services" element={<Services />} />
                <Route
                  path="/classScheduler"
                  element={
                    <ClassScheduler events={events} setEvents={setEvents} />
                  }
                />
                <Route path="/classLists" element={<ClassLists />} />
                <Route path="/userList/:eventId" element={<UserList />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/google-auth-test" element={<GoogleAuthTest />} />
                <Route path="/login" element={<Login />} />
                <Route path="/donate" element={<Donate />} />
                <Route path="/register" element={<Register />} />
                <Route path="/policies" element={<Policies />} />
                <Route path="/myClasses" element={<MyClasses />} />
                <Route
                  path="/participationForm"
                  element={<ParticipationForm />}
                />
                <Route path="/userProfile" element={<UserProfile />} />
                <Route path="/invoicing" element={<Invoicing />} />
                <Route path="/adminDashboard" element={<AdminDashboard />} />
                <Route
                  path="/cart"
                  element={
                    <CartPage onCheckout={handleCheckout} userId={userId} />
                  }
                />
                <Route
                  path="/stripeCheckout"
                  element={
                    <StripeCheckoutPage
                      cartItems={checkoutData.cartItems}
                      totalAmount={checkoutData.totalAmount}
                      userId={userId}
                      eventId={selectedEventId}
                    />
                  }
                />
                <Route
                  path="/registration-success"
                  element={<RegistrationSuccess />}
                />

                {/* 2) New route for EditClasses */}
                <Route path="/editClasses" element={<EditClasses />} />
              </Routes>
              <Footer />
            </div>
          </ClassesProvider>
        </CartProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
