// ParticipationFormLayout.js

import React from "react";
import "./ParticipationForm.css";

const ParticipationFormLayout = ({
  formData,
  handleChange,
  handleSubmit,
  isSubmitting
}) => {
  return (
    <div className="professional-form-inner">
      <h1 className="professional-form-title">
        Participation & Emergency Contact Form
      </h1>

      <form onSubmit={handleSubmit}>
        {/* Participation Section */}
        <h2 className="professional-section-title">
          Participation Information
        </h2>

        {/* First Row: First Name and Last Name */}
        <div className="form-row">
          <div className="form-col-half">
            <label htmlFor="firstName" className="professional-form-label">
              First Name <span className="required">*</span>
            </label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              placeholder="First Name"
              className="professional-form-input"
              onChange={handleChange}
              value={formData.firstName}
              required
            />
          </div>

          <div className="form-col-half">
            <label htmlFor="lastName" className="professional-form-label">
              Last Name <span className="required">*</span>
            </label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Last Name"
              className="professional-form-input"
              onChange={handleChange}
              value={formData.lastName}
              required
            />
          </div>
        </div>

        {/* Second Row: Age and Date of Birth */}
        <div className="form-row">
          <div className="form-col-half">
            <label htmlFor="age" className="professional-form-label">
              Age <span className="required">*</span>
            </label>
            <input
              type="number"
              name="age"
              id="age"
              placeholder="Age"
              className="professional-form-input"
              onChange={handleChange}
              value={formData.age}
              required
              min="0"
            />
          </div>

          <div className="form-col-half">
            <label htmlFor="dateOfBirth" className="professional-form-label">
              Date of Birth <span className="required">*</span>
            </label>
            <input
              type="date"
              name="dateOfBirth"
              id="dateOfBirth"
              className="professional-form-input"
              onChange={handleChange}
              value={formData.dateOfBirth}
              required
            />
          </div>
        </div>

        {/* Third Row: Address */}
        <div className="form-row">
          <div className="form-col-full">
            <label htmlFor="address" className="professional-form-label">
              Address <span className="required">*</span>
            </label>
            <input
              type="text"
              name="address"
              id="address"
              placeholder="Address"
              className="professional-form-input"
              onChange={handleChange}
              value={formData.address}
              required
            />
          </div>
        </div>

        {/* Fourth Row: Email and Phone */}
        <div className="form-row">
          <div className="form-col-half">
            <label htmlFor="email" className="professional-form-label">
              Email <span className="required">*</span>
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              className="professional-form-input"
              onChange={handleChange}
              value={formData.email}
              required
            />
          </div>

          <div className="form-col-half">
            <label htmlFor="phone" className="professional-form-label">
              Phone <span className="required">*</span>
            </label>
            <input
              type="tel"
              name="phone"
              id="phone"
              placeholder="Phone (e.g., 123-456-7890)"
              className="professional-form-input"
              onChange={handleChange}
              value={formData.phone}
              required
            />
          </div>
        </div>

        {/* Divider */}
        <hr className="professional-form-divider" />

        {/* Consent Section */}
        <h2 className="professional-section-title">Consent and Waivers</h2>

        {/* Responsible Adult Consent */}
        <div className="form-row">
          <div className="form-col-full">
            <div className="checkbox-container">
              <input
                type="checkbox"
                name="responsibleAdultConsent"
                id="responsibleAdultConsent"
                className="custom-checkbox"
                checked={formData.responsibleAdultConsent || false}
                onChange={handleChange}
                required
              />
              <label htmlFor="responsibleAdultConsent">
                I understand that a responsible adult must attend with I/DD
                participant, unless otherwise confirmed.
              </label>
            </div>
          </div>
        </div>

        {/* Photo Consent */}
        <div className="form-row">
          <div className="form-col-full">
            <div className="checkbox-container">
              <input
                type="checkbox"
                name="photo_permission"
                id="photo_permission"
                className="custom-checkbox"
                checked={formData.photo_permission}
                onChange={handleChange}
              />
              <label htmlFor="photo_permission">
                I consent to the use of photos on social media.
              </label>
            </div>
          </div>
        </div>

        {/* Photo Consent for Marketing Purposes */}
        <div className="form-row">
          <div className="form-col-full">
            <div className="checkbox-container">
              <input
                type="checkbox"
                name="photo_consent"
                id="photo_consent"
                className="custom-checkbox"
                checked={formData.photo_consent || false}
                onChange={handleChange}
              />
              <label htmlFor="photo_consent">
                I consent to have my photos taken and used for marketing
                purposes.
              </label>
            </div>
          </div>
        </div>

        {/* Divider */}
        <hr className="professional-form-divider" />

        {/* Parent/Legal Guardian Consent Section */}
        <h2 className="professional-section-title">
          Parent/Legal Guardian Consent
        </h2>
        <p className="professional-form-text">
          Parent/Legal Guardian Consent and Waiver of Liability: I understand
          that participation carries inherent risks. In consideration of my
          participation, I waive any claims against the released parties. If I
          am a parent/legal guardian of a minor Participant, I assume these
          risks on behalf of the minor.
        </p>

        {/* Parent/Guardian Consent Checkbox */}
        <div className="form-row">
          <div className="form-col-half">
            <div className="checkbox-container">
              <input
                type="checkbox"
                name="parentGuardianConsent"
                id="parentGuardianConsent"
                className="custom-checkbox"
                checked={formData.parentGuardianConsent || false}
                onChange={handleChange}
                required
              />
              <label htmlFor="parentGuardianConsent">Yes, I understand</label>
            </div>
          </div>
        </div>

        {/* Parent/Guardian Name Input */}
        <p className="professional-form-text">
          By typing my First and Last Name, I acknowledge that I have read and
          agree to the above waiver.
        </p>
        <div className="form-row">
          <div className="form-col-full">
            <label
              htmlFor="parentGuardianName"
              className="professional-form-label"
            >
              Parent/Guardian Name <span className="required">*</span>
            </label>
            <input
              type="text"
              name="parentGuardianName"
              id="parentGuardianName"
              placeholder="Type your First and Last Name as Parent/Guardian"
              className="professional-form-input"
              onChange={handleChange}
              value={formData.parentGuardianName}
              required
            />
          </div>
        </div>

        {/* Divider */}
        <hr className="professional-form-divider" />

        {/* Emergency Contact Section */}
        <h2 className="professional-section-title">
          Emergency Contact Information
        </h2>

        {/* First Emergency Contact */}
        <div className="form-row">
          <div className="form-col-half">
            <label
              htmlFor="emergencyContact1Name"
              className="professional-form-label"
            >
              Contact 1 Name <span className="required">*</span>
            </label>
            <input
              type="text"
              name="emergencyContact1Name"
              id="emergencyContact1Name"
              placeholder="Contact 1 Name"
              className="professional-form-input"
              onChange={handleChange}
              value={formData.emergencyContact1Name}
              required
            />
          </div>

          <div className="form-col-half">
            <label
              htmlFor="emergencyContact1Phone"
              className="professional-form-label"
            >
              Contact 1 Phone Number <span className="required">*</span>
            </label>
            <input
              type="tel"
              name="emergencyContact1Phone"
              id="emergencyContact1Phone"
              placeholder="Contact 1 Phone Number"
              className="professional-form-input"
              onChange={handleChange}
              value={formData.emergencyContact1Phone}
              required
            />
          </div>
        </div>

        {/* Second Emergency Contact */}
        <div className="form-row">
          <div className="form-col-half">
            <label
              htmlFor="emergencyContact2Name"
              className="professional-form-label"
            >
              Contact 2 Name <span className="required">*</span>
            </label>
            <input
              type="text"
              name="emergencyContact2Name"
              id="emergencyContact2Name"
              placeholder="Contact 2 Name"
              className="professional-form-input"
              onChange={handleChange}
              value={formData.emergencyContact2Name}
              required
            />
          </div>

          <div className="form-col-half">
            <label
              htmlFor="emergencyContact2Phone"
              className="professional-form-label"
            >
              Contact 2 Phone Number <span className="required">*</span>
            </label>
            <input
              type="tel"
              name="emergencyContact2Phone"
              id="emergencyContact2Phone"
              placeholder="Contact 2 Phone Number"
              className="professional-form-input"
              onChange={handleChange}
              value={formData.emergencyContact2Phone}
              required
            />
          </div>
        </div>

        {/* Disability and Allergies Section */}
        <div className="form-row">
          <div className="form-col-half">
            <label htmlFor="disability" className="professional-form-label">
              Disability
            </label>
            <input
              type="text"
              name="disability"
              id="disability"
              placeholder="Disability"
              className="professional-form-input"
              onChange={handleChange}
              value={formData.disability}
            />
          </div>

          <div className="form-col-half">
            <label htmlFor="allergies" className="professional-form-label">
              Allergies
            </label>
            <input
              type="text"
              name="allergies"
              id="allergies"
              placeholder="Allergies"
              className="professional-form-input"
              onChange={handleChange}
              value={formData.allergies}
            />
          </div>
        </div>

        {/* Behaviors Section */}
        <div className="form-row">
          <div className="form-col-full">
            <label htmlFor="behaviors" className="professional-form-label">
              Behaviors
            </label>
            <input
              type="text"
              name="behaviors"
              id="behaviors"
              placeholder="Behaviors"
              className="professional-form-input"
              onChange={handleChange}
              value={formData.behaviors}
            />
          </div>
        </div>

        {/* Conserved Section */}
        <div className="form-row">
          <div className="form-col-full">
            <label className="professional-form-label">Conserved:</label>
            <div className="radio-group">
              <input
                type="radio"
                name="conserved"
                id="conservedYes"
                value="true"
                onChange={handleChange}
                checked={formData.conserved === true}
              />
              <label htmlFor="conservedYes">Yes</label>
            </div>
            <div className="radio-group">
              <input
                type="radio"
                name="conserved"
                id="conservedNo"
                value="false"
                onChange={handleChange}
                checked={formData.conserved === false}
              />
              <label htmlFor="conservedNo">No</label>
            </div>
          </div>
        </div>

        {/* Preferred Hospital and Primary Physician Section */}
        <div className="form-row">
          <div className="form-col-half">
            <label
              htmlFor="preferredHospital"
              className="professional-form-label"
            >
              Preferred Hospital
            </label>
            <input
              type="text"
              name="preferredHospital"
              id="preferredHospital"
              placeholder="Preferred Hospital"
              className="professional-form-input"
              onChange={handleChange}
              value={formData.preferredHospital}
            />
          </div>

          <div className="form-col-half">
            <label
              htmlFor="primaryPhysician"
              className="professional-form-label"
            >
              Primary Physician
            </label>
            <input
              type="text"
              name="primaryPhysician"
              id="primaryPhysician"
              placeholder="Primary Physician"
              className="professional-form-input"
              onChange={handleChange}
              value={formData.primaryPhysician}
            />
          </div>
        </div>

        {/* Service Coordinator Section */}
        <div className="form-row">
          <div className="form-col-half">
            <label
              htmlFor="serviceCoordinator"
              className="professional-form-label"
            >
              Alta Service Coordinator
            </label>
            <input
              type="text"
              name="serviceCoordinator"
              id="serviceCoordinator"
              placeholder="Alta Service Coordinator"
              className="professional-form-input"
              onChange={handleChange}
              value={formData.serviceCoordinator}
            />
          </div>

          <div className="form-col-half">
            <label
              htmlFor="serviceCoordinatorPhone"
              className="professional-form-label"
            >
              Service Coordinator Phone Number
            </label>
            <input
              type="tel"
              name="serviceCoordinatorPhone"
              id="serviceCoordinatorPhone"
              placeholder="Phone Number"
              className="professional-form-input"
              onChange={handleChange}
              value={formData.serviceCoordinatorPhone}
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="form-row">
          <div className="form-col-full">
            <button
              type="submit"
              className="professional-form-button"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit Form"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ParticipationFormLayout;
