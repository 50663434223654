// CartPage.js

import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { useCart } from "../CartContext/CartContext";
import { useAuth } from "../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { jwtDecode } from "jwt-decode";
import "./CartPage.css";

// Define API_BASE_URL so that endpoints can be appended correctly.
const API_BASE_URL =
  window.location.hostname === "localhost" ? "http://localhost:3000" : "";

const CartPage = () => {
  const { cartItems, removeFromCart, clearCart } = useCart();
  const { isAuthenticated, userRole, getToken } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [participationFormCompleted, setParticipationFormCompleted] =
    useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [registrationError, setRegistrationError] = useState("");

  // States for invoicing (for Alta users)
  const [invoiceData, setInvoiceData] = useState(null);
  const [monthlyTotals, setMonthlyTotals] = useState(null);

  // PayPal options
  const initialOptions = {
    "client-id":
      "AZMzuQYJ6QBFjl9sx-IdI_2OaOHd7AhJuhJ1iTtFLdjqOBNrc5eg23vcMvvx7WOiizE3RHt610W7-OGH",
    currency: "USD",
    intent: "capture"
  };

  // Calculate total price of cart items.
  const calculateTotal = useCallback(() => {
    const total = cartItems.reduce(
      (sum, item) => sum + parseFloat(item.price || 0),
      0
    );
    console.log("Calculated cart total:", total);
    return total;
  }, [cartItems]);

  // Format cart item details for display.
  const formatCartItem = (item) => {
    const formatDateTimeFromString = (dateTimeString) => {
      if (!dateTimeString) return "No Date Provided";
      const date = new Date(dateTimeString);
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true
      };
      return date.toLocaleString(undefined, options);
    };
    const formattedDate = formatDateTimeFromString(item.start_time);
    const title = item.title || item.name || "No Title Provided";
    const imageUrl = item.image_url || "";
    return { title, formattedDate, imageUrl };
  };

  // Separate free and paid cart items
  const freeCartItems = cartItems.filter(
    (item) => parseFloat(item.price || 0) === 0
  );
  const paidCartItems = cartItems.filter(
    (item) => parseFloat(item.price || 0) > 0
  );

  // Handle checkout for paid items (for privileged users without payment)
  const handleJoinClassClick = async () => {
    if (!token) {
      console.error("No token available.");
      alert("Authentication required. Please sign in again.");
      return;
    }
    try {
      setRegistrationError("");
      console.log(
        "Attempting event registration for paid items. Base URL:",
        API_BASE_URL
      );
      console.log("User ID:", userId);
      console.log("Paid Cart Items:", paidCartItems);
      const responses = await Promise.all(
        paidCartItems.map((item, index) => {
          const payload = { user_id: userId, event_id: item.id };
          console.log(`Registering paid cart item ${index + 1}:`, payload);
          return axios.post(
            `${API_BASE_URL}/api/event-registrations`,
            payload,
            {
              headers: { Authorization: `Bearer ${token}` }
            }
          );
        })
      );
      console.log("Event registration responses for paid items:", responses);
      const allSucceeded = responses.every(
        (response) => response.status === 200
      );
      if (allSucceeded) {
        const registeredPaidClasses = paidCartItems.map((item) => ({
          ...item,
          title: item.title || item.name || "No Title Provided"
        }));
        console.log(
          "All paid registrations succeeded. Storing classes:",
          registeredPaidClasses
        );
        localStorage.setItem(
          "registeredClasses",
          JSON.stringify(registeredPaidClasses)
        );
        // Remove paid items from cart
        paidCartItems.forEach((item) => {
          const index = cartItems.findIndex(
            (cartItem) => cartItem.id === item.id
          );
          if (index !== -1) {
            removeFromCart(index);
          }
        });
        navigate("/registration-success");
      } else {
        console.error("One or more paid registrations failed.");
        throw new Error("Some registrations failed.");
      }
    } catch (error) {
      console.error("Registration error for paid items:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setRegistrationError(error.response.data.message);
      } else {
        setRegistrationError(
          "There was an issue processing your registration. Please try again."
        );
      }
    }
  };

  // Handle joining a free class individually.
  const handleJoinFreeClass = async (item) => {
    if (!token) {
      console.error("No token available.");
      alert("Authentication required. Please sign in again.");
      return;
    }
    try {
      const payload = { user_id: userId, event_id: item.id };
      console.log("Registering free class:", payload);
      const response = await axios.post(
        `${API_BASE_URL}/api/event-registrations`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      console.log("Free class registration response:", response.data);
      // Remove the free class from cart.
      const itemIndex = cartItems.findIndex(
        (cartItem) => cartItem.id === item.id
      );
      if (itemIndex !== -1) {
        removeFromCart(itemIndex);
      }
      alert("Successfully joined the free class!");
    } catch (error) {
      console.error("Error joining free class:", error);
      alert("There was an error joining the free class. Please try again.");
    }
  };

  // Fetch token and userId on mount.
  useEffect(() => {
    const retrievedToken = getToken();
    console.log("Retrieved token:", retrievedToken);
    setToken(retrievedToken);
    if (retrievedToken) {
      try {
        const decodedToken = jwtDecode(retrievedToken);
        console.log("Decoded token:", decodedToken);
        setUserId(decodedToken.user_id);
      } catch (decodeError) {
        console.error("Error decoding token:", decodeError);
        setUserId(null);
      }
    } else {
      setIsLoading(false);
    }
  }, [getToken]);

  // Fetch participation form status.
  useEffect(() => {
    const fetchParticipationFormStatus = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/users/${userId}`,
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );
        console.log("Participation form status response:", response.data);
        setParticipationFormCompleted(
          response.data.participation_form_completed
        );
      } catch (error) {
        console.error("Error fetching participation form status:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (userId && token) {
      fetchParticipationFormStatus();
    }
  }, [userId, token]);

  // Fetch invoice data for Alta users.
  useEffect(() => {
    const normalizedRole = userRole ? userRole.toLowerCase() : "";
    if (userId && token && normalizedRole === "alta") {
      const fetchInvoiceDataForUser = async () => {
        try {
          const billingMonth = moment().month() + 1;
          const billingYear = moment().year();
          console.log(
            `Fetching invoice data for Alta user ${userId} for billingMonth=${billingMonth}, billingYear=${billingYear}`
          );
          const response = await axios.get(
            `${API_BASE_URL}/api/invoicing/invoice-by-user/${userId}?billingMonth=${billingMonth}&billingYear=${billingYear}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          console.log("Invoice data response:", response.data);
          setInvoiceData(response.data);
        } catch (error) {
          console.error("Error fetching invoice data for Alta user:", error);
        }
      };
      fetchInvoiceDataForUser();
    }
  }, [userId, token, userRole]);

  // Fetch monthly totals for Alta users.
  useEffect(() => {
    const normalizedRole = userRole ? userRole.toLowerCase() : "";
    if (normalizedRole === "alta" && userId && token) {
      const fetchMonthlyTotals = async () => {
        try {
          const billingMonth = moment().month() + 1;
          const billingYear = moment().year();
          console.log(
            `Fetching monthly totals for Alta user ${userId} for billingMonth=${billingMonth}, billingYear=${billingYear}`
          );
          const response = await axios.get(
            `${API_BASE_URL}/api/invoicing/monthly-totals/${userId}?billingMonth=${billingMonth}&billingYear=${billingYear}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          console.log("Monthly totals response:", response.data);
          setMonthlyTotals(response.data);
        } catch (error) {
          console.error("Error fetching monthly totals for Alta user:", error);
        }
      };
      fetchMonthlyTotals();
    }
  }, [userId, token, userRole]);

  if (isLoading) {
    return (
      <div className="text-center">
        <p>Loading...</p>
      </div>
    );
  }
  if (!isAuthenticated) {
    return (
      <div className="cart-page container">
        <h1>Your Cart</h1>
        <div className="mb-4 text-center">
          <p>Please sign in to proceed to checkout.</p>
          <span
            onClick={() => navigate("/login")}
            style={{
              color: "#007bff",
              cursor: "pointer",
              textDecoration: "underline"
            }}
          >
            Click here to sign in.
          </span>
        </div>
      </div>
    );
  }
  if (participationFormCompleted === false) {
    return (
      <div className="text-center">
        <p>
          Please complete the participation form before proceeding to checkout.
        </p>
        <button
          className="btn btn-primary"
          onClick={() => navigate("/participation-form")}
        >
          Go to Participation Form
        </button>
      </div>
    );
  }

  // Normalize role string and determine privileges.
  const normalizedRole = userRole ? userRole.toLowerCase() : "";
  const isSDP = normalizedRole === "self determination program (sdp)";
  const isStaff = normalizedRole === "staff";
  const isAdmin = normalizedRole === "administrator";
  const isAlta = normalizedRole === "alta";
  // Privileged roles can register without payment.
  const privileged = isSDP || isStaff || isAdmin || isAlta;
  // Check if there are any paid items.
  const hasPaidItems = paidCartItems.length > 0;
  const cartTotal = calculateTotal();

  // For Alta users, calculate invoice totals.
  let currentInvoiceTotal = 0;
  if (monthlyTotals && monthlyTotals.totalAmount) {
    currentInvoiceTotal = parseFloat(monthlyTotals.totalAmount) || 0;
  }
  let altaNewTotal = currentInvoiceTotal + cartTotal;

  return (
    <PayPalScriptProvider options={initialOptions}>
      <div className="cart-page container">
        <h1>Your Cart</h1>
        {registrationError && (
          <div className="alert alert-danger" role="alert">
            {registrationError}
          </div>
        )}
        {/* Section for free classes */}
        {freeCartItems.length > 0 && (
          <div className="free-classes-section">
            <h2>Free Classes</h2>
            <ul className="cart-list">
              {freeCartItems.map((item, index) => {
                const { title, formattedDate, imageUrl } = formatCartItem(item);
                return (
                  <li key={index} className="cart-item">
                    {imageUrl && (
                      <img
                        src={imageUrl}
                        alt={title}
                        className="cart-item-image"
                      />
                    )}
                    <div className="cart-item-details">
                      <div className="cart-item-name">{title}</div>
                      <div className="cart-item-date">{formattedDate}</div>
                    </div>
                    <div className="cart-item-actions">
                      <button
                        className="btn btn-success"
                        onClick={() => handleJoinFreeClass(item)}
                      >
                        Join Free Class
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {/* Section for paid classes */}
        {paidCartItems.length > 0 && (
          <div className="paid-classes-section">
            <h2>Paid Classes</h2>
            <ul className="cart-list">
              {paidCartItems.map((item, index) => {
                const { title, formattedDate, imageUrl } = formatCartItem(item);
                return (
                  <li key={index} className="cart-item">
                    {imageUrl && (
                      <img
                        src={imageUrl}
                        alt={title}
                        className="cart-item-image"
                      />
                    )}
                    <div className="cart-item-details">
                      <div className="cart-item-name">{title}</div>
                      <div className="cart-item-date">{formattedDate}</div>
                    </div>
                    <div className="cart-item-actions">
                      <button
                        className="btn btn-danger remove-btn"
                        onClick={() => {
                          const idx = cartItems.findIndex(
                            (cartItem) => cartItem.id === item.id
                          );
                          if (idx !== -1) removeFromCart(idx);
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
            <div className="text-center mt-4">
              {privileged ? (
                <div>
                  {(!isAlta || (isAlta && altaNewTotal <= 650)) && (
                    <button
                      className="btn btn-success mb-2"
                      onClick={handleJoinClassClick}
                    >
                      Checkout without Payment
                    </button>
                  )}
                  {paidCartItems.length > 0 && hasPaidItems && (
                    <>
                      <h3>Total: ${cartTotal.toFixed(2)}</h3>
                      <div
                        className="buttons-container"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          padding: "20px 0"
                        }}
                      >
                        <div style={{ width: "50%" }}>
                          <PayPalButtons
                            style={{ layout: "horizontal", height: 50 }}
                            createOrder={(data, actions) => {
                              const totalAmount = cartTotal.toFixed(2);
                              console.log(
                                "Total Amount for PayPal:",
                                totalAmount
                              );
                              return actions.order.create({
                                purchase_units: [
                                  { amount: { value: totalAmount } }
                                ]
                              });
                            }}
                            onApprove={(data, actions) => {
                              return actions.order
                                .capture()
                                .then((details) => {
                                  console.log(
                                    "PayPal Payment Successful:",
                                    details
                                  );
                                  navigate("/registration-success");
                                })
                                .catch((err) => {
                                  console.error(
                                    "Error capturing PayPal order:",
                                    err
                                  );
                                  alert(
                                    "There was an issue processing your PayPal payment. Please try again."
                                  );
                                });
                            }}
                            onError={(err) => {
                              console.error("PayPal Checkout Error:", err);
                              alert(
                                "There was an issue processing your PayPal payment. Please try again."
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center mt-2 mb-3">
                        <hr style={{ width: "40%" }} />
                        <span style={{ padding: "0 10px" }}>or</span>
                        <hr style={{ width: "40%" }} />
                      </div>
                      <button
                        className="stripe-button btn btn-primary"
                        onClick={() =>
                          navigate("/stripeCheckout", {
                            state: {
                              userId,
                              eventId: paidCartItems.map((item) => item.id)
                            }
                          })
                        }
                      >
                        Checkout with{" "}
                        <span className="stripe-word">Credit Card</span>
                      </button>
                    </>
                  )}
                  {paidCartItems.length > 0 && !hasPaidItems && (
                    <>
                      <h3>Total: ${cartTotal.toFixed(2)}</h3>
                    </>
                  )}
                </div>
              ) : (
                <div>
                  <h3>Total: ${cartTotal.toFixed(2)}</h3>
                  <div
                    className="buttons-container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      padding: "20px 0"
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <PayPalButtons
                        style={{ layout: "horizontal", height: 50 }}
                        createOrder={(data, actions) => {
                          const totalAmount = cartTotal.toFixed(2);
                          console.log("Total Amount for PayPal:", totalAmount);
                          return actions.order.create({
                            purchase_units: [{ amount: { value: totalAmount } }]
                          });
                        }}
                        onApprove={(data, actions) => {
                          return actions.order
                            .capture()
                            .then((details) => {
                              console.log(
                                "PayPal Payment Successful:",
                                details
                              );
                              navigate("/registration-success");
                            })
                            .catch((err) => {
                              console.error(
                                "Error capturing PayPal order:",
                                err
                              );
                              alert(
                                "There was an issue processing your PayPal payment. Please try again."
                              );
                            });
                        }}
                        onError={(err) => {
                          console.error("PayPal Checkout Error:", err);
                          alert(
                            "There was an issue processing your PayPal payment. Please try again."
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mt-2 mb-3">
                    <hr style={{ width: "40%" }} />
                    <span style={{ padding: "0 10px" }}>or</span>
                    <hr style={{ width: "40%" }} />
                  </div>
                  <button
                    className="stripe-button btn btn-primary"
                    onClick={() =>
                      navigate("/stripeCheckout", {
                        state: {
                          userId,
                          eventId: paidCartItems.map((item) => item.id)
                        }
                      })
                    }
                  >
                    Checkout with{" "}
                    <span className="stripe-word">Credit Card</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </PayPalScriptProvider>
  );
};

export default CartPage;
