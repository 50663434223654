import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ClassLists.css";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const ClassLists = () => {
  const [events, setEvents] = useState([]);
  const [showPastClasses, setShowPastClasses] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [enableDateFilter, setEnableDateFilter] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const eventsEndpoint = `https://www.ptemployment.org/api/classes/class-schedules/`;

    axios
      .get(eventsEndpoint)
      .then((response) => {
        const sortedEvents = response.data.sort((a, b) => {
          const dateA = new Date(a.start_time);
          const dateB = new Date(b.start_time);
          return dateA - dateB;
        });
        setEvents(sortedEvents);
      })
      .catch((error) => console.error("Error fetching events:", error));
  }, []);

  const toggleShowPastClasses = () => {
    setShowPastClasses(!showPastClasses);
  };

  const handleUserListNavigation = (event) => {
    navigate(`/userList/${event.id}`, { state: { eventDetails: event } });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleDateFilterToggle = () => {
    setEnableDateFilter(!enableDateFilter);
    if (enableDateFilter) {
      setSelectedDate("");
    }
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handlePrevMonth = () => {
    const prevMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    setCurrentDate(prevMonth);
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    setCurrentDate(nextMonth);
  };

  const currentTime = new Date();

  // Filter events based on search query and (if enabled) by the selected date
  const filteredEvents = events.filter((event) => {
    const eventTitle = event.title.toLowerCase();
    const eventDateObj = new Date(event.start_time);
    const eventDateStr = eventDateObj.toLocaleDateString().toLowerCase();
    const eventTimeStr = eventDateObj.toLocaleTimeString().toLowerCase();

    const matchesSearch =
      eventTitle.includes(searchQuery) ||
      eventDateStr.includes(searchQuery) ||
      eventTimeStr.includes(searchQuery);

    let matchesDateFilter = true;
    if (enableDateFilter && selectedDate) {
      // Append "T00:00:00" to ensure the date is parsed as local time.
      const selected = new Date(selectedDate + "T00:00:00");
      matchesDateFilter =
        eventDateObj.getFullYear() === selected.getFullYear() &&
        eventDateObj.getMonth() === selected.getMonth() &&
        eventDateObj.getDate() === selected.getDate();
    }
    return matchesSearch && matchesDateFilter;
  });

  // When date filter is off, group events by current month; when on, use filtered events directly.
  const monthYearStr = currentDate.toLocaleString("default", {
    month: "long",
    year: "numeric"
  });
  const eventsByMonth = filteredEvents.reduce((acc, event) => {
    const eventDate = new Date(event.start_time);
    // Filter past events if not showing past classes
    if (!showPastClasses && eventDate < currentTime) {
      return acc;
    }
    const monthYear = eventDate.toLocaleString("default", {
      month: "long",
      year: "numeric"
    });
    if (!acc[monthYear]) {
      acc[monthYear] = [];
    }
    acc[monthYear].push(event);
    return acc;
  }, {});

  const eventsToShow = enableDateFilter
    ? filteredEvents
    : eventsByMonth[monthYearStr] || [];

  const isCurrentMonthPast =
    currentDate.getMonth() < currentTime.getMonth() ||
    currentDate.getFullYear() < currentTime.getFullYear();

  return (
    <div className="class-lists-container">
      {/* Back to Profile Button */}
      <div className="mb-3">
        <button
          onClick={() => navigate("/userProfile")}
          className="btn btn-primary"
        >
          Back to Profile
        </button>
      </div>

      <h1 className="class-lists-title">Class Lists</h1>

      {/* Controls */}
      <div className="class-lists-controls mb-3">
        <div className="controls-left">
          <button className="btn btn-info me-2" onClick={toggleShowPastClasses}>
            {showPastClasses ? "Hide Past Classes" : "Show Past Classes"}
          </button>
          <input
            type="text"
            className="form-control search-input"
            placeholder="Search classes by name, date or time..."
            onChange={handleSearchChange}
          />
        </div>
        <div className="controls-right">
          <label className="date-filter-label">
            <input
              type="checkbox"
              checked={enableDateFilter}
              onChange={handleDateFilterToggle}
            />
            Filter by Date
          </label>
          {enableDateFilter && (
            <input
              type="date"
              className="form-control date-picker"
              value={selectedDate}
              onChange={handleDateChange}
            />
          )}
        </div>
      </div>

      {/* Month Navigation (only when date filter is off) */}
      {!enableDateFilter && (
        <div className="month-navigation mb-3">
          <button className="btn btn-light me-2" onClick={handlePrevMonth}>
            <FaChevronLeft /> Prev
          </button>
          <h2 className="month-title">{monthYearStr}</h2>
          <button className="btn btn-light ms-2" onClick={handleNextMonth}>
            Next <FaChevronRight />
          </button>
        </div>
      )}

      {/* Alert for Past Months */}
      {!showPastClasses && isCurrentMonthPast && !enableDateFilter && (
        <div className="alert alert-warning text-center">
          You are viewing past months. Click "Show Past Classes" to display
          classes from this month.
        </div>
      )}

      {/* Event List */}
      {eventsToShow.length > 0 ? (
        eventsToShow.map((event) => (
          <div key={event.id} className="event-item">
            <div className="event-info">
              <div className="event-title">{event.title}</div>
              <div className="event-datetime">
                {new Date(event.start_time).toLocaleDateString()}{" "}
                {new Date(event.start_time).toLocaleTimeString()}
              </div>
            </div>
            <div className="event-actions">
              <button
                className="btn btn-primary user-list-btn"
                onClick={() => handleUserListNavigation(event)}
              >
                User List
              </button>
            </div>
          </div>
        ))
      ) : (
        <div className="no-events">
          No classes scheduled for{" "}
          {enableDateFilter
            ? selectedDate
              ? new Date(selectedDate + "T00:00:00").toLocaleDateString()
              : "the selected date"
            : "this month"}
          .
        </div>
      )}
    </div>
  );
};

export default ClassLists;
