// src/components/NavBar/NavBar.js

import React, { useState, useEffect } from "react";
import { useCart } from "../CartContext/CartContext";
import { Link, useLocation } from "react-router-dom";
import logo from "../../Assets/Logo/PtELogo.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import "./NavBar.css";
import { jwtDecode } from "jwt-decode";

import axios from "axios"; // Import axios for API calls

const NavBar = () => {
  const [activeDropdown, setActiveDropdown] = useState("");
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const token = localStorage.getItem("token");
  const { cartItems } = useCart();
  const [displayName, setDisplayName] = useState(""); // State for display name
  const [isFetching, setIsFetching] = useState(false); // State for loading
  const [fetchError, setFetchError] = useState(null); // State for errors
  const location = useLocation(); // Hook to access current route

  const showDropdown = (dropdownName) => {
    setActiveDropdown(dropdownName);
  };

  const hideDropdown = () => {
    setActiveDropdown("");
  };

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  // Handle Logout
  const handleLogout = () => {
    localStorage.removeItem("token");
    setDisplayName("");
    window.location.href = "/"; // Redirect to home page
  };

  // Decode token and fetch user details
  useEffect(() => {
    const fetchUserDetails = async (userId, username) => {
      setIsFetching(true);
      setFetchError(null);
      try {
        // Replace the URL below with your actual API endpoint to fetch user details
        const response = await axios.get(
          `https://www.ptemployment.org/api/users/${userId}`,
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        const user = response.data;

        if (user.firstName && user.lastName) {
          setDisplayName(`${user.firstName} ${user.lastName}`);
        } else {
          setDisplayName(username);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        setFetchError("Failed to fetch user details.");
        setDisplayName(username); // Fallback to username
      } finally {
        setIsFetching(false);
      }
    };

    if (token) {
      try {
        const decoded = jwtDecode(token);
        const username = decoded.username || decoded.name || "User";
        const userId = decoded.user_id;

        if (userId) {
          fetchUserDetails(userId, username);
        } else {
          setDisplayName(username);
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        setDisplayName("User");
      }
    } else {
      setDisplayName("");
    }
  }, [token]);

  // Function to determine if a link is active
  const isActive = (path) => location.pathname === path;

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        {/* Logo */}
        <Link to="/" className="navbar-brand">
          <img src={logo} alt="Logo" className="logo" />
        </Link>

        {/* Toggler for mobile view */}
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded={!isNavCollapsed ? true : false}
          aria-label="Toggle navigation"
          onClick={handleNavCollapse}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navbar Links */}
        <div
          className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
          id="navbarNav"
        >
          <ul className="navbar-nav ml-auto">
            {/* Home Link */}
            <li className="nav-item">
              <Link
                to="/"
                className={`nav-link ${isActive("/") ? "active" : ""}`}
              >
                HOME
              </Link>
            </li>

            {/* About Us Dropdown */}
            <li
              className={`nav-item dropdown ${
                activeDropdown === "about" ? "show" : ""
              }`}
              onMouseEnter={() => showDropdown("about")}
              onMouseLeave={hideDropdown}
            >
              <Link
                to="/aboutUs"
                className="nav-link dropdown-toggle"
                id="navbarDropdownAbout"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={activeDropdown === "about" ? "true" : "false"}
              >
                ABOUT US
              </Link>
              <div
                className={`dropdown-menu ${
                  activeDropdown === "about" ? "show" : ""
                }`}
                aria-labelledby="navbarDropdownAbout"
              >
                <Link
                  to="/aboutUs#board"
                  className="dropdown-item"
                  onClick={hideDropdown}
                >
                  Board of Directors
                </Link>
                <Link
                  to="/aboutUs#partners"
                  className="dropdown-item"
                  onClick={hideDropdown}
                >
                  Partners & Supporters
                </Link>
              </div>
            </li>

            {/* Services Dropdown */}
            <li
              className={`nav-item dropdown ${
                activeDropdown === "services" ? "show" : ""
              }`}
              onMouseEnter={() => showDropdown("services")}
              onMouseLeave={hideDropdown}
            >
              <Link
                to="/services"
                className="nav-link dropdown-toggle"
                id="navbarDropdownServices"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={activeDropdown === "services" ? "true" : "false"}
              >
                SERVICES
              </Link>
              <div
                className={`dropdown-menu ${
                  activeDropdown === "services" ? "show" : ""
                }`}
                aria-labelledby="navbarDropdownServices"
              >
                <Link
                  to="/monthlySchedule"
                  className="dropdown-item"
                  onClick={hideDropdown}
                >
                  Monthly Schedule
                </Link>
                <Link
                  to="/services#classDescriptions"
                  className="dropdown-item"
                  onClick={hideDropdown}
                >
                  Class Descriptions
                </Link>
              </div>
            </li>

            {/* Contact Link */}
            <li className="nav-item">
              <Link
                to="/contact"
                className={`nav-link ${isActive("/contact") ? "active" : ""}`}
              >
                CONTACT
              </Link>
            </li>

            {/* Cart Icon */}
            <li className="nav-item position-relative">
              <Link
                to="/cart"
                className={`nav-link cart-icon-link ${
                  isActive("/cart") ? "active" : ""
                }`}
              >
                {/* You can replace the SVG with an icon library like React Icons if preferred */}
                <svg
                  className="cart-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="9" cy="21" r="1"></circle>
                  <circle cx="20" cy="21" r="1"></circle>
                  <path d="M1 1h4l2.6 12.59a2 2 0 001.92 1.41H19a2 2 0 001.98-1.67L23 6H6"></path>
                </svg>
                {cartItems.length > 0 && (
                  <span className="cart-count">{cartItems.length}</span>
                )}
              </Link>
            </li>

            {/* User Profile / Log In */}
            <li className="nav-item dropdown">
              {token ? (
                <>
                  <Link
                    to="#"
                    className="nav-link dropdown-toggle d-flex align-items-center nav-user-info"
                    id="userDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      src="/Assets/Photos/User/User.png"
                      alt="User"
                      className="user-icon"
                    />
                    <span className="user-name"> {displayName} logged in</span>
                  </Link>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="userDropdown"
                  >
                    <Link to="/userProfile" className="dropdown-item">
                      Profile
                    </Link>
                    <Link to="/myClasses" className="dropdown-item">
                      My Classes
                    </Link>
                    <div className="dropdown-divider"></div>
                    <button
                      className="dropdown-item logout-button"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </div>
                </>
              ) : (
                <Link to="/login" className="nav-link">
                  Log In
                </Link>
              )}
            </li>

            {/* Donate Button */}
            <li className="nav-item">
              <Link to="/donate" className="nav-link donate-button">
                Donate
                {/* You can replace the SVG with an icon library like React Icons if preferred */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="-5 0 28 18"
                  fill="white"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-heart icon"
                >
                  <path d="M20.8 4.6c-1.4-1.4-3.6-1.4-5 0L12 8.4l-3.8-3.8c-1.4-1.4-3.6-1.4-5 0s-1.4 3.6 0 5L12 21.6l8.8-8.8c1.4-1.4 1.4-3.6 0-5z"></path>
                </svg>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/* Optional: Display loading or error messages */}
      {isFetching && (
        <div className="user-fetch-status">
          <span>Loading...</span>
        </div>
      )}
      {fetchError && (
        <div className="user-fetch-status error">
          <span>{fetchError}</span>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
