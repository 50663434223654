// src/components/ClassScheduler/ClassScheduler.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import EventDetailsPopup from "../EventDetailsPopup/EventDetailsPopup";
import "./ClassScheduler.css";
import RecurringDatePicker from "../RecurringDatePicker/RecurringDatePicker";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

// Import default images (adjust these paths as needed)
import defaultImage from "../../Assets/DefaultImage.webp";
import defaultImage2 from "../../Assets/DefaultImage2.png";
import defaultImage3 from "../../Assets/DefaultImage3.png";

// Array of default images to choose from
const defaultImages = [defaultImage, defaultImage2, defaultImage3];

// Function to round time to the next 15-minute interval
const roundToNext15Minutes = (time) => {
  const minutes = time.minute();
  const roundedMinutes = Math.ceil(minutes / 15) * 15;
  if (roundedMinutes === 60) {
    return time.clone().startOf("hour").add(1, "hour");
  } else {
    return time.clone().minute(roundedMinutes).second(0);
  }
};

const localizer = momentLocalizer(moment);

const ClassScheduler = () => {
  const userRole = localStorage.getItem("role");
  const [events, setEvents] = useState([]);

  const initialStartTime = roundToNext15Minutes(moment());
  const initialEndTime = initialStartTime.clone().add(1, "hour");

  const [eventData, setEventData] = useState({
    title: "",
    start_time: initialStartTime,
    end_time: initialEndTime,
    description: "",
    price: "",
    hours: "",
    description_url: "",
    event_color: "",
    is_all_day: false,
    image_url: ""
  });

  const [selectedClassId, setSelectedClassId] = useState("");
  const [classes, setClasses] = useState([]);
  const [isAutofilled, setIsAutofilled] = useState(false);
  const [recurringDates, setRecurringDates] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showEventDetails, setShowEventDetails] = useState(false);
  const [showRecurringForm, setShowRecurringForm] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsEndpoint =
          "https://www.ptemployment.org/api/classes/class-schedules";
        const token = localStorage.getItem("token");
        const response = await axios.get(eventsEndpoint, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setEvents(response.data);
        setClasses(response.data);
      } catch (error) {
        console.error("Error fetching events:", error);
        alert("Failed to fetch events. Please try again later.");
      }
    };
    fetchEvents();
  }, []);

  const parseEventTimeAsLocal = (timeString) => {
    const localTime = moment.utc(timeString).local();
    return localTime.toDate();
  };

  const eventsForCalendar = events.map((event) => {
    const start = parseEventTimeAsLocal(event.start_time);
    const end = parseEventTimeAsLocal(event.end_time);
    return {
      ...event,
      start,
      end,
      title: event.title,
      originalStartTime: event.start_time,
      originalEndTime: event.end_time
    };
  });

  const handleDateChange = (field, date) => {
    let newEventData = { ...eventData };
    const roundedTime = roundToNext15Minutes(moment(date));
    newEventData[field] = roundedTime;
    if (field === "start_time") {
      newEventData.end_time = roundedTime.clone().add(1, "hour");
    }
    setEventData(newEventData);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventData({
      ...eventData,
      [name]: value
    });
  };

  const handleClassSelectionChange = (e) => {
    setSelectedClassId(e.target.value);
  };

  const handleAutofill = () => {
    const selectedEvent = classes.find(
      (event) => event.title === selectedClassId
    );
    if (selectedEvent) {
      setEventData({
        ...eventData,
        title: selectedEvent.title,
        description: selectedEvent.description,
        price: selectedEvent.price,
        hours: selectedEvent.hours,
        image_url: selectedEvent.image_url
      });
      setIsAutofilled(true);
    }
  };

  const handleDateSelect = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setRecurringDates((prevDates) =>
      prevDates.includes(formattedDate)
        ? prevDates.filter((d) => d !== formattedDate)
        : [...prevDates, formattedDate]
    );
  };

  const pastelColors = [
    { name: "Pastel Blush", color: "#ffc3a0" },
    { name: "Pastel Salmon", color: "#ffb0ac" },
    { name: "Pastel Pink", color: "#ffb6c1" },
    { name: "Pastel Rose", color: "#ff6f61" },
    { name: "Pastel Red", color: "#ff6961" },
    { name: "Pastel Coral", color: "#ff6b81" },
    { name: "Pastel Ruby", color: "#e75480" },
    { name: "Crimson", color: "#dc143c" },
    { name: "Regular Red", color: "#ff0000" },
    { name: "Dark Red", color: "#8b0000" },
    { name: "Pastel Peach", color: "#ffcc99" },
    { name: "Pastel Tangerine", color: "#ffcc80" },
    { name: "Pastel Orange", color: "#ffbb91" },
    { name: "Light Orange", color: "#ffd580" },
    { name: "Amber", color: "#ffbf00" },
    { name: "Regular Orange", color: "#ffa500" },
    { name: "Dark Orange", color: "#ff8c00" },
    { name: "Vivid Orange", color: "#ff4500" },
    { name: "Burnt Orange", color: "#cc5500" },
    { name: "Light Yellow", color: "#ffffe0" },
    { name: "Pastel Lemon", color: "#fff44f" },
    { name: "Pastel Yellow", color: "#fdfd96" },
    { name: "Regular Yellow", color: "#ffff00" },
    { name: "Mustard Yellow", color: "#ffdb58" },
    { name: "Pastel Gold", color: "#ffd700" },
    { name: "Dark Yellow", color: "#ffcc00" },
    { name: "Goldenrod", color: "#daa520" },
    { name: "Pastel Moss Green", color: "#addfad" },
    { name: "Pastel Lime", color: "#bfff00" },
    { name: "Pastel Mint", color: "#98ff98" },
    { name: "Pastel Green", color: "#77dd77" },
    { name: "Regular Green", color: "#00ff00" },
    { name: "Lime Green", color: "#32cd32" },
    { name: "Forest Green", color: "#228b22" },
    { name: "Dark Green", color: "#006400" },
    { name: "Pastel Azure", color: "#f0ffff" },
    { name: "Pastel Periwinkle", color: "#c3cde6" },
    { name: "Pastel Blue", color: "#aec6cf" },
    { name: "Pastel Aqua", color: "#7fffd4" },
    { name: "Pastel Teal", color: "#99ffcc" },
    { name: "Pastel Turquoise", color: "#40e0d0" },
    { name: "Pastel Sky", color: "#87ceeb" },
    { name: "Cyan", color: "#00ffff" },
    { name: "Regular Blue", color: "#0000ff" },
    { name: "Pastel Lavender Blush", color: "#fff0f5" },
    { name: "Lavender", color: "#e6e6fa" },
    { name: "Pastel Lavender", color: "#e6e6fa" },
    { name: "Pastel Purple", color: "#b39eb5" },
    { name: "Pastel Lilac", color: "#c8a2c8" },
    { name: "Pastel Plum", color: "#dda0dd" },
    { name: "Violet", color: "#ee82ee" },
    { name: "Pastel Orchid", color: "#db70db" },
    { name: "Magenta", color: "#ff00ff" },
    { name: "Regular Purple", color: "#800080" }
  ];

  const handleColorSelect = (color) => {
    setEventData({ ...eventData, event_color: color });
  };

  const ColorPicker = ({ selectedColor, onSelectColor }) => (
    <div className="color-picker">
      {pastelColors.map((colorOption) => (
        <div
          key={colorOption.color}
          className={`color-option ${
            selectedColor === colorOption.color ? "selected" : ""
          }`}
          style={{ backgroundColor: colorOption.color }}
          onClick={() => onSelectColor(colorOption.color)}
          title={colorOption.name}
        />
      ))}
    </div>
  );

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setShowEventDetails(true);
  };

  const handleCloseDetails = () => {
    setShowEventDetails(false);
    setSelectedEvent(null);
  };

  const handleEditEvent = async (updatedEvent) => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("No token found. Please log in.");
      return;
    }
    try {
      const updateEndpoint = `https://www.ptemployment.org/api/classes/class-schedules/${updatedEvent.id}`;
      const response = await axios.put(updateEndpoint, updatedEvent, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      setEvents(
        events.map((event) =>
          event.id === updatedEvent.id ? response.data : event
        )
      );
      alert("Event updated successfully.");
    } catch (error) {
      console.error("Error updating event:", error);
      alert("Failed to update the event. Please try again.");
    }
  };

  const handleDeleteEvent = async (eventId) => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("No token found. Please log in.");
      return;
    }
    try {
      const response = await axios.delete(
        `https://www.ptemployment.org/api/classes/class-schedules/${eventId}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      if (response.status === 200 || response.status === 204) {
        setEvents(events.filter((event) => event.id !== eventId));
        alert("Event deleted successfully.");
      } else {
        alert("Failed to delete the event. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting event:", error);
      alert("Failed to delete the event. Please try again.");
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
    }
  };

  // Updated handleImageUpload: if an image file is provided, upload it.
  // Otherwise, if no image is provided and no URL is set, pick a random default.
  const handleImageUpload = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("No token found. Please log in.");
      return null;
    }
    if (imageFile) {
      const formData = new FormData();
      formData.append("file", imageFile);
      try {
        const uploadEndpoint = "https://www.ptemployment.org/upload";
        const uploadResponse = await axios.post(uploadEndpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
          },
          responseType: "text"
        });
        if (uploadResponse.status === 200) {
          const imageUrl = uploadResponse.data.split(" to ")[1];
          setEventData((prevEventData) => ({
            ...prevEventData,
            image_url: imageUrl
          }));
          return imageUrl;
        }
      } catch (uploadError) {
        console.error("Error uploading image:", uploadError);
        alert("Failed to upload image. Please try again.");
      }
    } else {
      // No image file provided
      if (!eventData.image_url) {
        const randomIndex = Math.floor(Math.random() * defaultImages.length);
        const randomDefault = defaultImages[randomIndex];
        setEventData((prev) => ({ ...prev, image_url: randomDefault }));
        return randomDefault;
      } else {
        return eventData.image_url;
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!eventData.price || parseFloat(eventData.price) === 0) {
      alert("Price cannot be zero or left blank. Please enter a valid price.");
      return;
    }
    if (!eventData.hours || parseFloat(eventData.hours) === 0) {
      alert(
        "Hours cannot be zero or left blank. Please enter a valid number of hours."
      );
      return;
    }
    if (!imageFile && !eventData.image_url) {
      const proceedWithoutImage = window.confirm(
        "No image selected. Do you want to proceed without an image?"
      );
      if (!proceedWithoutImage) {
        return;
      }
    }
    const imageUrl = await handleImageUpload();
    const start_time = moment(eventData.start_time)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
    const end_time = moment(eventData.end_time)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
    const eventDataToSubmit = {
      ...eventData,
      start_time,
      end_time,
      image_url: imageUrl || eventData.image_url,
      price: eventData.price || null,
      hours: eventData.hours || null
    };
    await submitEvent(eventDataToSubmit, eventData.start_time);
  };

  const submitEvent = async (eventData, eventDate = null) => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("No token found. Please log in.");
      return;
    }
    try {
      const postEndpoint =
        "https://www.ptemployment.org/api/classes/class-schedules";
      const response = await axios.post(postEndpoint, eventData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      if (response.status === 201) {
        setEvents((prevEvents) => [...prevEvents, response.data]);
        if (eventDate) {
          setCurrentDate(moment(eventDate).toDate());
        }
        const resetStartTime = roundToNext15Minutes(moment());
        const resetEndTime = resetStartTime.clone().add(1, "hour");
        setEventData({
          title: "",
          start_time: resetStartTime,
          end_time: resetEndTime,
          description: "",
          price: "",
          hours: "",
          description_url: "",
          event_color: "",
          is_all_day: false,
          image_url: ""
        });
        setImageFile(null);
        setIsAutofilled(false);
        alert("Event added successfully!");
      }
    } catch (error) {
      console.error("Error adding event:", error);
      alert("Failed to add the event. Please try again.");
    }
  };

  // When toggling recurring mode on, clear previous recurring selections.
  const handleRecurringClick = () => {
    if (!showRecurringForm) {
      setRecurringDates([]);
    }
    setShowRecurringForm((prev) => !prev);
  };

  // Only create recurring events for dates the user explicitly selects.
  const handleConfirmRecurringDates = async () => {
    const imageUrl = await handleImageUpload();
    for (const date of recurringDates) {
      const selectedDate = moment(date, "YYYY-MM-DD");
      const startTime = selectedDate.clone().set({
        hour: eventData.start_time.hour(),
        minute: eventData.start_time.minute(),
        second: eventData.start_time.second()
      });
      const endTime = startTime
        .clone()
        .add(eventData.end_time.diff(eventData.start_time));
      const formattedStart = startTime.utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
      const formattedEnd = endTime.utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
      const newEvent = {
        ...eventData,
        start_time: formattedStart,
        end_time: formattedEnd,
        image_url: imageUrl || eventData.image_url,
        price: eventData.price === "" ? null : eventData.price,
        hours: eventData.hours === "" ? null : eventData.hours
      };
      await submitEvent(newEvent, startTime);
    }
    setRecurringDates([]);
    setShowRecurringForm(false);
  };

  const uniqueClassTitles = [...new Set(classes.map((event) => event.title))];

  const formatTimeFromString = (timeString) => {
    if (!timeString) return "";
    const localTime = moment.utc(timeString).local();
    return localTime.format("h:mm A");
  };

  const CustomEvent = ({ event }) => (
    <div className="custom-event">
      <strong>{event.title}</strong>
      <div>
        {formatTimeFromString(event.originalStartTime)} -{" "}
        {formatTimeFromString(event.originalEndTime)}
      </div>
    </div>
  );

  const eventStyleGetter = (event) => {
    const backgroundColor = event.event_color || "#3174ad";
    return {
      style: {
        backgroundColor,
        borderRadius: "5px",
        opacity: 0.9,
        color: "#000", // Black text for calendar events
        border: "none",
        padding: "2px 4px"
      }
    };
  };

  return (
    <div className="scheduler-container">
      <header className="scheduler-header">
        <button
          className="btn btn-back"
          onClick={() => navigate("/userProfile")}
        >
          &larr; Back to Profile
        </button>
        <h1>Class Scheduler</h1>
      </header>
      <div className="scheduler-content">
        <div className="form-container">
          <div className="class-selection">
            <select
              value={selectedClassId}
              onChange={handleClassSelectionChange}
              className="class-dropdown"
            >
              <option value="">Select a Previous Class</option>
              {uniqueClassTitles.map((title, index) => (
                <option key={index} value={title}>
                  {title}
                </option>
              ))}
            </select>
            <button className="btn btn-autofill" onClick={handleAutofill}>
              Autofill
            </button>
          </div>
          <form onSubmit={handleSubmit} className="scheduler-form">
            <div className="form-group">
              <label htmlFor="title">Class Title</label>
              <input
                type="text"
                name="title"
                id="title"
                value={eventData.title}
                onChange={handleInputChange}
                placeholder="Enter class title"
                required
              />
            </div>
            {!isAutofilled && (
              <div className="form-group">
                <label htmlFor="image-upload">Event Image</label>
                <input
                  type="file"
                  id="image-upload"
                  onChange={handleImageChange}
                  accept="image/*"
                />
              </div>
            )}
            <div className="disclaimer">
              <p>
                For recurring events, the date selected here is for the initial
                event only. Use the calendar below to select additional dates.
              </p>
            </div>
            <div className="datetime-group">
              <div className="datetime-field">
                <label>Start Time</label>
                <Datetime
                  value={eventData.start_time}
                  onChange={(date) => handleDateChange("start_time", date)}
                  timeConstraints={{ minutes: { min: 0, max: 59, step: 15 } }}
                  dateFormat="YYYY-MM-DD"
                  timeFormat="hh:mm A"
                  inputProps={{ className: "datetime-input" }}
                />
              </div>
              <div className="datetime-field">
                <label>End Time</label>
                <Datetime
                  value={eventData.end_time}
                  onChange={(date) => handleDateChange("end_time", date)}
                  timeConstraints={{ minutes: { min: 0, max: 59, step: 15 } }}
                  dateFormat="YYYY-MM-DD"
                  timeFormat="hh:mm A"
                  inputProps={{ className: "datetime-input" }}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea
                name="description"
                id="description"
                value={eventData.description}
                onChange={handleInputChange}
                placeholder="Enter event description"
              />
            </div>
            <div className="form-group inline-group">
              <div className="inline-field">
                <label htmlFor="price">Price</label>
                <input
                  type="number"
                  name="price"
                  id="price"
                  value={eventData.price}
                  onChange={handleInputChange}
                  placeholder="Enter price"
                  required
                />
              </div>
              <div className="inline-field">
                <label htmlFor="hours">Hours</label>
                <input
                  type="number"
                  name="hours"
                  id="hours"
                  value={eventData.hours}
                  onChange={handleInputChange}
                  placeholder="Enter hours"
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label>Event Color</label>
              <ColorPicker
                selectedColor={eventData.event_color}
                onSelectColor={handleColorSelect}
              />
            </div>
            <div className="form-actions">
              <button
                type="button"
                className="btn btn-recurring"
                onClick={handleRecurringClick}
              >
                {showRecurringForm ? "Cancel Recurring" : "Make Recurring"}
              </button>
              {showRecurringForm && (
                <div className="recurring-section">
                  <p className="disclaimer">
                    Click on the days you want the event to recur. Selected days
                    will highlight.
                  </p>
                  <RecurringDatePicker
                    onDateSelect={handleDateSelect}
                    selectedDates={recurringDates}
                  />
                  <button
                    type="button"
                    className="btn btn-confirm"
                    onClick={handleConfirmRecurringDates}
                  >
                    Confirm Dates
                  </button>
                </div>
              )}
              {!showRecurringForm && (
                <button type="submit" className="btn btn-submit">
                  Add Class
                </button>
              )}
            </div>
          </form>
        </div>
        <div className="calendar-container">
          <Calendar
            localizer={localizer}
            events={eventsForCalendar}
            onSelectEvent={handleSelectEvent}
            eventPropGetter={eventStyleGetter}
            style={{ minHeight: "3300px" }}
            views={["month"]}
            components={{ event: CustomEvent }}
            startAccessor="start"
            endAccessor="end"
            date={currentDate}
            onNavigate={(date) => setCurrentDate(date)}
          />
        </div>
      </div>
      {showEventDetails && selectedEvent && (
        <EventDetailsPopup
          event={selectedEvent}
          onEdit={handleEditEvent}
          onDelete={handleDeleteEvent}
          onClose={handleCloseDetails}
          userRole={userRole}
          displayOnly={false}
        />
      )}
    </div>
  );
};

export default ClassScheduler;
