// src/components/Invoicing/Invoicing.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Invoicing.css";
import moment from "moment";

// **Define the Backend Base URL**
const API_BASE_URL =
  window.location.hostname === "localhost"
    ? "http://localhost:3000/api" // Development backend
    : "/api"; // Production backend

const Invoicing = () => {
  // ---------------------------------------
  // **State Variables**
  // ---------------------------------------
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [events, setEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(moment().startOf("month"));
  const [isEditingBillingInfo, setIsEditingBillingInfo] = useState(false);
  const [isConfirmingEmailAll, setIsConfirmingEmailAll] = useState(false);
  const [isAddingEvent, setIsAddingEvent] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const [editBillingInfo, setEditBillingInfo] = useState({
    first_name: "",
    last_name: "",
    billing_code: "",
    address: "",
    phone: "",
    email: ""
  });
  const [billToInfo, setBillToInfo] = useState({
    name: "N/A",
    address: "N/A",
    phone: "N/A",
    email: "N/A"
  });
  const [newEvent, setNewEvent] = useState({
    title: "",
    price: "",
    description: "",
    start_time: "",
    end_time: "",
    billing_hours: ""
  });
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmAddEvent, setShowConfirmAddEvent] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const token = location.state?.token || localStorage.getItem("token");
  const [currentAvailablePage, setCurrentAvailablePage] = useState(1);
  const eventsPerPage = 5;
  const [searchQuery, setSearchQuery] = useState("");
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isMonthlyView, setIsMonthlyView] = useState(false);
  // For monthly view, store aggregated invoices
  const [monthlyInvoices, setMonthlyInvoices] = useState([]);

  // ------------------------------
  // **Helper Function: Get Invoice Data for a User**
  // ------------------------------
  const getInvoiceDataForUser = async (user) => {
    const userId = user.user_id || user.id;
    const billingMonth = currentMonth.month() + 1;
    const billingYear = currentMonth.year();
    const endpoint = `${API_BASE_URL}/invoicing/invoice-by-user/${userId}?billingMonth=${billingMonth}&billingYear=${billingYear}`;
    console.log(`Fetching invoice data from endpoint: ${endpoint}`);
    try {
      const res = await axios.get(endpoint, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log(`Invoice data for user ${userId}:`, res.data);
      return res.data;
    } catch (error) {
      console.error(`Error fetching invoice data for user ${userId}:`, error);
      return null;
    }
  };

  // ------------------------------
  // **Handlers and Data Fetching Functions**
  // ------------------------------
  const handleSearchChange = (e) => setSearchQuery(e.target.value);

  const handleRemoveUserFromEvent = (event) => {
    setEventToDelete(event);
    setShowRemoveModal(true);
  };

  const confirmRemoveUserFromEvent = async (event) => {
    setIsRemoving(true);
    try {
      const userId = selectedUser?.user_id || selectedUser?.id;
      const eventId = event.event_id || event.id;
      await axios.post(
        `${API_BASE_URL}/invoicing/events/${eventId}/unregister`,
        { user_id: userId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setEvents((prev) =>
        prev.filter(
          (e) => (e.event_id || e.id) !== (event.event_id || event.id)
        )
      );
      setShowSuccessMessage(true);
      setErrorMessage("");
    } catch (error) {
      alert(
        error.response?.data?.error ||
          "Error removing user from event. Please try again."
      );
      setErrorMessage(
        error.response?.data?.error ||
          "Error removing user from event. Please try again."
      );
    } finally {
      setIsRemoving(false);
      setShowRemoveModal(false);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (!token) return;
        const response = await axios.get(`${API_BASE_URL}/users`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const usersWithFullNames = await Promise.all(
          response.data.map(async (user) => {
            const userId = user.user_id || user.id;
            const partRes = await axios.get(
              `${API_BASE_URL}/check-form-completion?user_id=${userId}`,
              { headers: { Authorization: `Bearer ${token}` } }
            );
            const { firstName, lastName } = partRes.data;
            return { ...user, first_name: firstName, last_name: lastName };
          })
        );
        setUsers(usersWithFullNames);
      } catch (error) {
        setErrorMessage(
          error.response?.data?.error ||
            "Error fetching users. Please try again."
        );
        setShowErrorMessage(true);
      }
    };
    fetchUsers();
  }, [token]);

  useEffect(() => {
    const hours = parseFloat(newEvent.billing_hours);
    const rate = parseFloat(newEvent.price);
    if (!isNaN(hours) && !isNaN(rate)) {
      setNewEvent((prev) => ({ ...prev, amount: (hours * rate).toFixed(2) }));
    } else {
      setNewEvent((prev) => ({ ...prev, amount: "" }));
    }
  }, [newEvent.billing_hours, newEvent.price]);

  const handleRegisterEvent = async (event) => {
    if (!selectedUser) {
      alert("Please select a user to register the event.");
      return;
    }
    const userId = selectedUser.user_id || selectedUser.id;
    try {
      await axios.post(
        `${API_BASE_URL}/invoicing/events/${event.id}/register`,
        { user_id: userId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert("Event registered successfully.");
      handleUserSelect(selectedUser);
    } catch (error) {
      alert(
        error.response?.data?.error ||
          "Error registering event. Please try again."
      );
      setErrorMessage(
        error.response?.data?.error ||
          "Error registering event. Please try again."
      );
      setShowErrorMessage(true);
    }
  };

  // When a user is selected, fetch their events, billing info, and invoice data
  const handleUserSelect = async (user) => {
    const userId = user.user_id || user.id;
    const selected = {
      ...user,
      fullName:
        `${user.first_name || ""} ${user.last_name || ""}`.trim() || user.email
    };
    setSelectedUser(selected);
    setIsEditingBillingInfo(false);
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
    setEditBillingInfo({
      first_name: user.first_name || "",
      last_name: user.last_name || "",
      billing_code: user.billing_code || "",
      address: billToInfo.address || "",
      phone: billToInfo.phone || "",
      email: user.email || ""
    });
    setEvents([]);
    setBillToInfo({ name: "N/A", address: "N/A", phone: "N/A", email: "N/A" });
    if (!token) return;
    try {
      // Fetch events for the user
      const eventsRes = await axios.get(
        `${API_BASE_URL}/users/${userId}/events`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const updated = eventsRes.data.map((event) => {
        const start = moment.utc(event.start_time);
        const end = moment.utc(event.end_time);
        const duration = moment.duration(end.diff(start)).asHours();
        return { ...event, billing_hours: duration, isEditing: false };
      });
      setEvents(updated);
      // Fetch billing info for the user (bill-to information)
      const billRes = await axios.get(
        `${API_BASE_URL}/invoicing/bill-to/${userId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const billInfo = billRes.data || {
        name: "N/A",
        address: "N/A",
        phone: "N/A",
        email: "N/A"
      };
      setBillToInfo(billInfo);
      setEditBillingInfo((prev) => ({
        ...prev,
        address: billInfo.address || "",
        phone: billInfo.phone || ""
      }));
      // Fetch all events from invoicing endpoint
      const allEventsRes = await axios.get(`${API_BASE_URL}/invoicing/events`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setAllEvents(allEventsRes.data.events);
      // Now, fetch and log the invoice data for this user
      const invoiceData = await getInvoiceDataForUser(selected);
      console.log("Fetched invoice data for selected user:", invoiceData);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error ||
          "Error fetching events or billing info. Please try again."
      );
      setShowErrorMessage(true);
    }
  };

  const handleEmailAllClick = () => setIsConfirmingEmailAll(true);

  const handleSendEmailAll = async () => {
    try {
      setIsConfirmingEmailAll(false);
      const billingMonth = currentMonth.month() + 1;
      const billingYear = currentMonth.year();
      const payload = { billingMonth, billingYear };
      const res = await axios.post(
        `${API_BASE_URL}/invoicing/send-invoice-email-all`,
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert(res.data.message);
      setShowSuccessMessage(true);
    } catch (error) {
      alert(
        error.response?.data?.error || "Error sending emails. Please try again."
      );
      setErrorMessage(
        error.response?.data?.error || "Error sending emails. Please try again."
      );
      setShowErrorMessage(true);
    }
  };

  const handleCancelEmailAll = () => setIsConfirmingEmailAll(false);

  // Preview Invoice for individual user view
  const previewInvoice = async () => {
    if (!token) {
      alert("Authentication failed.");
      return;
    }
    try {
      const userId = selectedUser?.user_id || selectedUser?.id;
      if (!userId) {
        alert("Select a user to preview invoice.");
        return;
      }
      const billingMonth = currentMonth.month() + 1;
      const billingYear = currentMonth.year();
      const payload = {
        user_id: userId,
        billingMonth,
        billingYear,
        first_name: editBillingInfo.first_name,
        last_name: editBillingInfo.last_name,
        address: editBillingInfo.address,
        phone: editBillingInfo.phone,
        email: editBillingInfo.email
      };
      const previewUrl = `${API_BASE_URL}/invoicing/invoice-pdf-preview`;
      const res = await axios.post(previewUrl, payload, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "blob"
      });
      const invoiceId =
        res.headers["x-invoice-id"] || res.headers["X-Invoice-Id"] || "N/A";
      alert(`Preview Invoice ID: ${invoiceId}`);
      const pdfBlob = new Blob([res.data], { type: "application/pdf" });
      window.open(window.URL.createObjectURL(pdfBlob), "_blank");
    } catch (error) {
      alert(
        error.response?.data?.error ||
          "Error previewing invoice. Please try again."
      );
    }
  };

  const sendInvoiceToUser = async () => {
    try {
      const userId = selectedUser?.user_id || selectedUser?.id;
      if (!userId) {
        alert("Select a user to send invoice.");
        return;
      }
      const billingMonth = currentMonth.month() + 1;
      const billingYear = currentMonth.year();
      const payload = { user_id: userId, billingMonth, billingYear };
      const res = await axios.post(
        `${API_BASE_URL}/invoicing/send-invoice-email`,
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert(res.data.message);
      setShowSuccessMessage(true);
    } catch (error) {
      alert(
        error.response?.data?.error ||
          "Error sending invoice. Please try again."
      );
      setErrorMessage(
        error.response?.data?.error ||
          "Error sending invoice. Please try again."
      );
      setShowErrorMessage(true);
    }
  };

  const getEventsForCurrentMonth = () =>
    events.filter((event) =>
      moment(event.start_time).startOf("month").isSame(currentMonth, "month")
    );

  const handleSaveBillingInfoClick = () => setShowConfirmSave(true);

  const handleConfirmSaveBillingInfo = async () => {
    try {
      const userId = selectedUser?.user_id || selectedUser?.id;
      const payload = {
        first_name: editBillingInfo.first_name,
        last_name: editBillingInfo.last_name,
        billing_code: editBillingInfo.billing_code,
        address: editBillingInfo.address,
        phone: editBillingInfo.phone,
        email: editBillingInfo.email
      };
      await axios.put(
        `${API_BASE_URL}/auth/update-user-details/${userId}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      setShowSuccessMessage(true);
      setShowConfirmSave(false);
      setIsEditingBillingInfo(false);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error ||
          "Error saving billing info. Please try again."
      );
      setShowErrorMessage(true);
      setShowConfirmSave(false);
    }
  };

  const handleCancelSaveBillingInfo = () => setShowConfirmSave(false);

  const handleAddEventClick = () => {
    setIsAddingEvent(true);
    setNewEvent({
      title: "",
      price: "",
      description: "",
      start_time: "",
      end_time: "",
      billing_hours: ""
    });
  };

  const handleNewEventChange = (field, value) =>
    setNewEvent((prev) => ({ ...prev, [field]: value }));

  const handleConfirmAddEvent = async () => {
    const { title, price, description, start_time, end_time, billing_hours } =
      newEvent;
    if (!title || !price || !start_time || !end_time || !billing_hours) {
      alert("Fill in all required fields.");
      return;
    }
    try {
      const userId = selectedUser?.user_id || selectedUser?.id;
      const payload = {
        title,
        price: parseFloat(price),
        description: description || "",
        start_time: new Date(start_time).toISOString(),
        end_time: new Date(end_time).toISOString(),
        billing_hours: parseFloat(billing_hours),
        billing_rate: parseFloat(price),
        billing_code: selectedUser.billing_code || ""
      };
      const res = await axios.post(
        `${API_BASE_URL}/invoicing/events`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      const createdEvent = res.data.event;
      await axios.post(
        `${API_BASE_URL}/invoicing/events/${createdEvent.id}/register`,
        { user_id: selectedUser.user_id || selectedUser.id },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert("Event created and registered successfully.");
      setEvents((prev) => [
        ...prev,
        {
          event_id: createdEvent.id,
          title: createdEvent.title,
          price: createdEvent.price,
          start_time: createdEvent.start_time,
          end_time: createdEvent.end_time,
          billing_hours: createdEvent.billing_hours,
          amount: createdEvent.amount,
          description: createdEvent.description,
          isEditing: false
        }
      ]);
      setIsAddingEvent(false);
      setNewEvent({
        title: "",
        price: "",
        description: "",
        start_time: "",
        end_time: "",
        billing_hours: ""
      });
      setShowSuccessMessage(true);
    } catch (error) {
      alert(
        error.response?.data?.error || "Error adding event. Please try again."
      );
      setErrorMessage(
        error.response?.data?.error || "Error adding event. Please try again."
      );
      setShowErrorMessage(true);
    }
  };

  const handleCancelAddEvent = () => {
    setIsAddingEvent(false);
    setNewEvent({
      title: "",
      price: "",
      description: "",
      start_time: "",
      end_time: "",
      billing_hours: ""
    });
  };

  // -------------------------------
  // Missing Definitions Added Here
  // -------------------------------

  // Function to change the current month
  const handleMonthChange = (increment) => {
    setCurrentMonth(currentMonth.clone().add(increment, "months"));
  };

  // Filter available events for the selected month
  const filteredAvailableEvents = allEvents.filter((event) =>
    moment(event.start_time).startOf("month").isSame(currentMonth, "month")
  );

  // Calculate total available pages based on eventsPerPage
  const totalAvailablePages = Math.ceil(
    filteredAvailableEvents.length / eventsPerPage
  );

  // Slice the events for the current available page
  const currentAvailableEvents = filteredAvailableEvents.slice(
    (currentAvailablePage - 1) * eventsPerPage,
    currentAvailablePage * eventsPerPage
  );

  // -------------------------------
  // Compute Aggregated Monthly Invoices (Monthly View)
  useEffect(() => {
    if (isMonthlyView && users.length > 0 && token) {
      const fetchAggregatedInvoices = async () => {
        try {
          const aggregated = await Promise.all(
            users.map(async (user) => {
              const userId = user.user_id || user.id;
              // Fetch the user's events
              const eventsRes = await axios.get(
                `${API_BASE_URL}/users/${userId}/events`,
                {
                  headers: { Authorization: `Bearer ${token}` }
                }
              );
              const userEvents = eventsRes.data.map((event) => {
                const start = moment.utc(event.start_time);
                const end = moment.utc(event.end_time);
                const hours = moment.duration(end.diff(start)).asHours();
                return { ...event, billing_hours: hours };
              });
              // Filter events for the selected month
              const monthlyEvents = userEvents.filter((event) =>
                moment(event.start_time)
                  .startOf("month")
                  .isSame(currentMonth, "month")
              );
              // Calculate the total amount owed
              const totalAmount = monthlyEvents.reduce((sum, event) => {
                const hours = parseFloat(event.billing_hours) || 0;
                const price = parseFloat(event.price) || 0;
                // If the event is paid, amount is 0
                const amountForEvent = event.is_paid ? 0 : hours * price;
                return sum + amountForEvent;
              }, 0);
              // Only fetch invoice id if there are events for the month
              let invoiceNumber = "N/A";
              if (monthlyEvents.length > 0) {
                try {
                  const payload = {
                    user_id: userId,
                    billingMonth: currentMonth.month() + 1,
                    billingYear: currentMonth.year(),
                    first_name: user.first_name || "",
                    last_name: user.last_name || "",
                    address: user.address || "N/A",
                    phone: user.phone || "N/A",
                    email: user.email || ""
                  };
                  const invRes = await axios.post(
                    `${API_BASE_URL}/invoicing/invoice-pdf-preview`,
                    payload,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json"
                      },
                      responseType: "arraybuffer",
                      transformResponse: [(data) => data]
                    }
                  );
                  const invoiceIdHeader = invRes.headers["x-invoice-id"];
                  invoiceNumber = invoiceIdHeader
                    ? invoiceIdHeader.toString()
                    : "N/A";
                } catch (err) {
                  console.error(
                    "Error fetching invoice id for user",
                    userId,
                    err
                  );
                  invoiceNumber = "N/A";
                }
              }
              return {
                clientName:
                  `${user.first_name || ""} ${user.last_name || ""}`.trim() ||
                  user.email,
                invoiceNumber,
                totalAmount: totalAmount.toFixed(2)
              };
            })
          );
          setMonthlyInvoices(aggregated);
        } catch (error) {
          console.error("Error fetching monthly invoices:", error);
        }
      };
      fetchAggregatedInvoices();
    }
  }, [isMonthlyView, currentMonth, users, token]);

  // -------------------------------
  // Render
  // -------------------------------
  return (
    <div className="invoicing-container container mt-4">
      {/* Toggle view button */}
      <div className="d-flex justify-content-end mb-3">
        <button
          className="btn btn-info"
          onClick={() => setIsMonthlyView(!isMonthlyView)}
        >
          {isMonthlyView ? "Switch to User View" : "Monthly Invoice View"}
        </button>
      </div>

      {isMonthlyView ? (
        <div>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <button
              className="btn btn-outline-primary"
              onClick={() => handleMonthChange(-1)}
            >
              ←
            </button>
            <span className="current-month h4 my-auto">
              {currentMonth.format("MMMM YYYY")}
            </span>
            <button
              className="btn btn-outline-primary"
              onClick={() => handleMonthChange(1)}
            >
              →
            </button>
          </div>
          <div className="monthly-invoice-grid">
            {monthlyInvoices.length > 0 ? (
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Invoice Number</th>
                    <th>Amount Owed</th>
                  </tr>
                </thead>
                <tbody>
                  {monthlyInvoices.map((invoice, index) => (
                    <tr key={index}>
                      <td>{invoice.clientName}</td>
                      <td>{invoice.invoiceNumber}</td>
                      <td>${invoice.totalAmount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No invoice data available for this month.</p>
            )}
          </div>
        </div>
      ) : (
        <>
          {/* User view section */}
          <h2 className="mb-4">Invoicing</h2>
          <div className="email-all-users mb-4">
            <button
              className="btn btn-danger w-100"
              onClick={handleEmailAllClick}
            >
              Email All Users for {currentMonth.format("MMMM YYYY")}
            </button>
          </div>
          {isConfirmingEmailAll && (
            <div className="confirmation-dialog card card-body mb-4">
              <p>
                This will send invoices to all users for{" "}
                {currentMonth.format("MMMM YYYY")}.
              </p>
              <button
                className="btn btn-success mr-2"
                onClick={handleSendEmailAll}
              >
                Send
              </button>
              <button
                className="btn btn-secondary"
                onClick={handleCancelEmailAll}
              >
                Cancel
              </button>
            </div>
          )}
          {showSuccessMessage && (
            <div
              className="alert alert-success alert-dismissible fade show"
              role="alert"
            >
              Operation completed successfully.
              <button
                type="button"
                className="close"
                onClick={() => setShowSuccessMessage(false)}
              >
                <span>&times;</span>
              </button>
            </div>
          )}
          {showErrorMessage && (
            <div
              className="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              {errorMessage}
              <button
                type="button"
                className="close"
                onClick={() => setShowErrorMessage(false)}
              >
                <span>&times;</span>
              </button>
            </div>
          )}
          <div className="user-list mb-4">
            <h3 className="mb-3">Users</h3>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search users..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
            <div className="card">
              <ul className="list-group list-group-flush">
                {users
                  .filter((user) => {
                    const displayName =
                      user.fullName ||
                      `${user.first_name || ""} ${
                        user.last_name || ""
                      }`.trim() ||
                      user.email ||
                      "Unnamed User";
                    return displayName
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase());
                  })
                  .map((user) => {
                    const displayName =
                      user.fullName ||
                      `${user.first_name || ""} ${
                        user.last_name || ""
                      }`.trim() ||
                      user.email ||
                      "Unnamed User";
                    const isSelected =
                      (selectedUser?.user_id &&
                        selectedUser.user_id === user.user_id) ||
                      (selectedUser?.id && selectedUser.id === user.id);
                    return (
                      <li
                        key={user.user_id || user.id}
                        className={`list-group-item ${
                          isSelected ? "selected-user" : ""
                        }`}
                        onClick={() => {
                          console.log("User clicked:", user);
                          handleUserSelect(user);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <span>{displayName}</span>
                          {isSelected && (
                            <span className="badge badge-primary badge-pill">
                              Selected
                            </span>
                          )}
                        </div>
                      </li>
                    );
                  })}
                {users.filter((user) => {
                  const displayName =
                    user.fullName ||
                    `${user.first_name || ""} ${user.last_name || ""}`.trim() ||
                    user.email ||
                    "Unnamed User";
                  return displayName
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase());
                }).length === 0 && (
                  <li className="list-group-item text-center text-muted">
                    No users found.
                  </li>
                )}
              </ul>
            </div>
          </div>
          {selectedUser && (
            <div className="user-events">
              <h3>
                {selectedUser.fullName || selectedUser.email}'s Events for{" "}
                {currentMonth.format("MMMM YYYY")}
              </h3>
              <button
                className="btn btn-warning mb-3"
                onClick={() => setIsEditingBillingInfo(!isEditingBillingInfo)}
              >
                {isEditingBillingInfo
                  ? "Cancel Edit"
                  : `Edit ${selectedUser.first_name} ${selectedUser.last_name}'s Billing Information`}
              </button>
              {isEditingBillingInfo && (
                <div className="card card-body mb-3">
                  <h4>Edit Billing Information</h4>
                  {/* Billing fields */}
                  <div className="form-group">
                    <label>First Name *</label>
                    <input
                      type="text"
                      value={editBillingInfo.first_name}
                      onChange={(e) =>
                        setEditBillingInfo({
                          ...editBillingInfo,
                          first_name: e.target.value
                        })
                      }
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Last Name *</label>
                    <input
                      type="text"
                      value={editBillingInfo.last_name}
                      onChange={(e) =>
                        setEditBillingInfo({
                          ...editBillingInfo,
                          last_name: e.target.value
                        })
                      }
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Address *</label>
                    <input
                      type="text"
                      value={editBillingInfo.address}
                      onChange={(e) =>
                        setEditBillingInfo({
                          ...editBillingInfo,
                          address: e.target.value
                        })
                      }
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Phone Number *</label>
                    <input
                      type="text"
                      value={editBillingInfo.phone}
                      onChange={(e) =>
                        setEditBillingInfo({
                          ...editBillingInfo,
                          phone: e.target.value
                        })
                      }
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Email *</label>
                    <input
                      type="email"
                      value={editBillingInfo.email}
                      onChange={(e) =>
                        setEditBillingInfo({
                          ...editBillingInfo,
                          email: e.target.value
                        })
                      }
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Billing Code *</label>
                    <input
                      type="text"
                      value={editBillingInfo.billing_code}
                      onChange={(e) =>
                        setEditBillingInfo({
                          ...editBillingInfo,
                          billing_code: e.target.value
                        })
                      }
                      className="form-control"
                      required
                    />
                  </div>
                  <button
                    className="btn btn-success mt-3"
                    onClick={handleSaveBillingInfoClick}
                  >
                    Save Billing Information
                  </button>
                  {showConfirmSave && (
                    <div className="confirmation-message card card-body mt-3">
                      <p>Are you sure you want to save these changes?</p>
                      <button
                        className="btn btn-primary mr-2"
                        onClick={handleConfirmSaveBillingInfo}
                      >
                        Confirm
                      </button>
                      <button
                        className="btn btn-secondary"
                        onClick={handleCancelSaveBillingInfo}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              )}
              <button
                className="btn btn-primary mb-3"
                onClick={handleAddEventClick}
              >
                Add Event
              </button>
              {isAddingEvent && (
                <div className="card card-body mb-3">
                  <h4>Add New Event</h4>
                  {/* New event fields */}
                  <div className="form-group">
                    <label>Title *</label>
                    <input
                      type="text"
                      value={newEvent.title}
                      onChange={(e) =>
                        handleNewEventChange("title", e.target.value)
                      }
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Start Date & Time *</label>
                    <input
                      type="datetime-local"
                      value={newEvent.start_time}
                      onChange={(e) =>
                        handleNewEventChange("start_time", e.target.value)
                      }
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>End Date & Time *</label>
                    <input
                      type="datetime-local"
                      value={newEvent.end_time}
                      onChange={(e) =>
                        handleNewEventChange("end_time", e.target.value)
                      }
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Number of Hours *</label>
                    <input
                      type="number"
                      step="0.01"
                      value={newEvent.billing_hours}
                      onChange={(e) =>
                        handleNewEventChange("billing_hours", e.target.value)
                      }
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Price *</label>
                    <input
                      type="number"
                      step="0.01"
                      value={newEvent.price}
                      onChange={(e) =>
                        handleNewEventChange("price", e.target.value)
                      }
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Description (Optional)</label>
                    <textarea
                      value={newEvent.description}
                      onChange={(e) =>
                        handleNewEventChange("description", e.target.value)
                      }
                      className="form-control"
                      rows="3"
                    ></textarea>
                  </div>
                  <button
                    className="btn btn-success mt-3 mr-2"
                    onClick={() => setShowConfirmAddEvent(true)}
                  >
                    Add Event
                  </button>
                  <button
                    className="btn btn-secondary mt-3"
                    onClick={handleCancelAddEvent}
                  >
                    Cancel
                  </button>
                  {showConfirmAddEvent && (
                    <div className="confirmation-dialog card card-body mt-3">
                      <p>Are you sure you want to add this event?</p>
                      <button
                        className="btn btn-primary mr-2"
                        onClick={handleConfirmAddEvent}
                      >
                        Confirm
                      </button>
                      <button
                        className="btn btn-secondary"
                        onClick={() => setShowConfirmAddEvent(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              )}
              <div className="month-navigation mb-4 d-flex justify-content-between">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => handleMonthChange(-1)}
                >
                  Previous Month
                </button>
                <span className="current-month h4 my-auto">
                  {currentMonth.format("MMMM YYYY")}
                </span>
                <button
                  className="btn btn-outline-primary"
                  onClick={() => handleMonthChange(1)}
                >
                  Next Month
                </button>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h4>Registered Events</h4>
                  {getEventsForCurrentMonth().length > 0 ? (
                    <ul className="list-group">
                      {getEventsForCurrentMonth().map((event) => (
                        <li key={event.event_id} className="list-group-item">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <strong>{event.title}</strong>
                              <br />
                              <small>
                                {moment(event.start_time).format(
                                  "MM/DD/YYYY HH:mm"
                                )}{" "}
                                -{" "}
                                {moment(event.end_time).format(
                                  "MM/DD/YYYY HH:mm"
                                )}
                              </small>
                              <br />
                              <span>Hours: {event.billing_hours}</span>
                            </div>
                            <button
                              className="btn btn-warning btn-sm"
                              onClick={() => handleRemoveUserFromEvent(event)}
                            >
                              Remove
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No events registered for this month.</p>
                  )}
                </div>
                <div className="col-md-6">
                  <h4>
                    Available Events for {currentMonth.format("MMMM YYYY")}
                  </h4>
                  {currentAvailableEvents.length > 0 ? (
                    <ul className="list-group">
                      {currentAvailableEvents.map((event) => {
                        const isRegistered = events.some(
                          (userEvent) => userEvent.event_id === event.id
                        );
                        return (
                          <li
                            key={event.id}
                            className="list-group-item d-flex justify-content-between align-items-center"
                          >
                            <div>
                              <strong>{event.title}</strong>
                              <br />
                              <small>
                                {moment(event.start_time).format(
                                  "MM/DD/YYYY HH:mm"
                                )}{" "}
                                -{" "}
                                {moment(event.end_time).format(
                                  "MM/DD/YYYY HH:mm"
                                )}
                              </small>
                            </div>
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => handleRegisterEvent(event)}
                              disabled={isRegistered}
                            >
                              {isRegistered ? "Registered" : "Register"}
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <p>No available events for this month.</p>
                  )}
                  {filteredAvailableEvents.length > eventsPerPage && (
                    <div className="d-flex justify-content-center mt-3">
                      <div className="available-events-pagination">
                        <ul className="pagination">
                          <li
                            className={`page-item ${
                              currentAvailablePage === 1 ? "disabled" : ""
                            }`}
                          >
                            <button
                              className="page-link"
                              onClick={() =>
                                setCurrentAvailablePage(
                                  currentAvailablePage - 1
                                )
                              }
                              disabled={currentAvailablePage === 1}
                            >
                              Previous
                            </button>
                          </li>
                          {Array.from(
                            { length: totalAvailablePages },
                            (_, index) => index + 1
                          ).map((page) => (
                            <li
                              key={page}
                              className={`page-item ${
                                currentAvailablePage === page ? "active" : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() => setCurrentAvailablePage(page)}
                              >
                                {page}
                              </button>
                            </li>
                          ))}
                          <li
                            className={`page-item ${
                              currentAvailablePage === totalAvailablePages
                                ? "disabled"
                                : ""
                            }`}
                          >
                            <button
                              className="page-link"
                              onClick={() =>
                                setCurrentAvailablePage(
                                  currentAvailablePage + 1
                                )
                              }
                              disabled={
                                currentAvailablePage === totalAvailablePages
                              }
                            >
                              Next
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {events.length > 0 && (
                <div className="d-flex justify-content-center mb-3 mt-4">
                  <button className="btn btn-primary" onClick={previewInvoice}>
                    Show Preview of {editBillingInfo.first_name}{" "}
                    {editBillingInfo.last_name} Invoice for{" "}
                    {currentMonth.format("MMMM YYYY")}
                  </button>
                </div>
              )}
              {events.length > 0 && (
                <div className="d-flex justify-content-center mb-3">
                  <button
                    className="btn btn-secondary"
                    onClick={sendInvoiceToUser}
                  >
                    Send {editBillingInfo.first_name}{" "}
                    {editBillingInfo.last_name} Invoice for{" "}
                    {currentMonth.format("MMMM YYYY")}
                  </button>
                </div>
              )}
              {showRemoveModal && eventToDelete && (
                <div
                  className="modal show d-block"
                  tabIndex="-1"
                  role="dialog"
                  aria-modal="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Confirm Removal</h5>
                        <button
                          type="button"
                          className="close"
                          onClick={() => setShowRemoveModal(false)}
                          aria-label="Close"
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p>
                          Remove {selectedUser.first_name}{" "}
                          {selectedUser.last_name} from event "
                          {eventToDelete.title}"?
                        </p>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={async () =>
                            await confirmRemoveUserFromEvent(eventToDelete)
                          }
                          disabled={isRemoving}
                        >
                          {isRemoving ? "Removing..." : "Remove"}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => setShowRemoveModal(false)}
                          disabled={isRemoving}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Invoicing;
