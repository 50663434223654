import { createContext, useState, useEffect } from "react";
import axios from "axios";

export const ClassesContext = createContext({
  classes: [],
  setClasses: () => {}
});

export const ClassesProvider = ({ children }) => {
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    const fetchClasses = async () => {
      const classesEndpoint = `https://www.ptemployment.org/api/class-schedules`;
      console.log(`Fetching classes from: ${classesEndpoint}`); // Log the full URL for verification
      try {
        const response = await axios.get(classesEndpoint);
        if (response.data && Array.isArray(response.data)) {
          setClasses(response.data);
        } else {
          // console.error("Fetched data is not an array:", response.data);
          setClasses([]);
        }
      } catch (error) {
        console.error("Error fetching classes:", error);
      }
    };

    fetchClasses();
  }, []);

  return (
    <ClassesContext.Provider value={{ classes, setClasses }}>
      {children}
    </ClassesContext.Provider>
  );
};
