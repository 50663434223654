import React from "react";
import { useNavigate } from "react-router-dom";
import "./Contact.css";

const Contact = () => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("token");

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default submission
    const formData = new FormData(event.target);
    const data = {
      firstName: formData.get("firstName"),
      lastName: formData.get("lastName"),
      email: formData.get("email"),
      subject: formData.get("subject"),
      message: formData.get("message")
    };

    try {
      const response = await fetch("/api/contact", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      });
      if (response.ok) {
        alert("Your message was sent successfully!");
      } else {
        alert("There was an error sending your message.");
      }
    } catch (error) {
      console.error("Error submitting contact form:", error);
      alert("There was an error sending your message.");
    }
  };

  return (
    <div className="contact-container">
      {isLoggedIn && (
        <div className="back-to-profile-top">
          <button
            onClick={() => navigate("/userProfile")}
            className="btn btn-primary"
          >
            Back to Profile
          </button>
        </div>
      )}

      <h1>Contact Us</h1>
      <p>9813 Fair Oaks Blvd, Suite A</p>
      <p>Fair Oaks, CA 95628</p>
      <p>916-784-9809</p>
      <p>
        <a href="mailto:info@pathwaystoemploymentgb.org">
          info@pathwaystoemploymentgb.org
        </a>
      </p>

      <div className="social-media-links">
        <a
          href="https://www.facebook.com/pathwaysempcorp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>
        <a
          href="https://www.instagram.com/pathwaystoemploymentgb/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
      </div>

      <div className="contact-form">
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="firstName">First Name*</label>
              <input type="text" id="firstName" name="firstName" required />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name*</label>
              <input type="text" id="lastName" name="lastName" required />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="email">Email Address*</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className="form-group">
              <label htmlFor="subject">Subject*</label>
              <input type="text" id="subject" name="subject" required />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="message">Message*</label>
            <textarea id="message" name="message" required></textarea>
          </div>

          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
