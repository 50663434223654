// EventDetailsPopup.js

import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { useCart } from "../CartContext/CartContext";
import "./EventDetailsPopup.css";
import moment from "moment-timezone";

const EventDetailsPopup = ({
  event,
  onEdit,
  onDelete,
  onClose,
  userRole, // Accept userRole as a prop
  displayOnly = false,
  refreshEvents
}) => {
  console.log("User Role in EventDetailsPopup:", userRole);

  const [editableEvent, setEditableEvent] = useState({
    title: event.title,
    start_time: event.start_time,
    end_time: event.end_time,
    description: event.description,
    price: event.price,
    description_url: event.description_url,
    event_color: event.event_color
  });
  const [isEditMode, setIsEditMode] = useState(false);

  // Use local timezone for comparison
  const isPastEvent = moment().isAfter(
    moment.utc(editableEvent.start_time).local()
  );

  const { addToCart } = useCart();

  // Use snake_case property names as returned by the backend
  const maxCapacity = event.max_capacity !== undefined ? event.max_capacity : 8;
  const currentEnrollments =
    event.current_enrollments !== undefined ? event.current_enrollments : 0;
  const isFull = currentEnrollments >= maxCapacity;

  // Determine the participant count color based on currentEnrollments
  let participantColorClass = "";
  if (currentEnrollments >= 6 && currentEnrollments < maxCapacity) {
    participantColorClass = "warning"; // Yellow
  } else if (isFull) {
    participantColorClass = "full"; // Red
  }

  const handleAddToCart = async () => {
    console.log("Event object:", event);

    const itemToAdd = {
      id: event.id,
      name: event.title,
      price: event.price,
      start_time: event.start_time,
      end_time: event.end_time,
      image_url: event.image_url
    };

    console.log("Adding to cart:", itemToAdd);
    addToCart(itemToAdd);

    // Add to cart without enrolling
    try {
      const response = await axios.post(
        `/api/events/${event.id}/add-to-cart`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );
      console.log("Add to Cart response:", response.data);
    } catch (error) {
      console.error("Error adding to cart:", error);
    }

    onClose();
  };

  // Convert UTC timestamp to local display format
  const formatDateTimeForDisplay = (isoString) => {
    const date = moment.utc(isoString).local();
    return date.format("MMMM D, YYYY h:mm A");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setEditableEvent({ ...editableEvent, [name]: value });

    if (name === "start_time") {
      const startTime = moment.utc(value).local();
      const endTime = startTime.clone().add(30, "minutes");
      const endTimeString = endTime.format("YYYY-MM-DDTHH:mm");

      setEditableEvent((prevState) => ({
        ...prevState,
        end_time: endTimeString
      }));
    }
  };

  const handleSave = () => {
    const updatedEvent = {
      ...event,
      ...editableEvent
    };

    console.log("Saving event with color:", updatedEvent.event_color);
    onEdit(updatedEvent);
    setIsEditMode(false);
  };

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      onDelete(event.id);
      onClose();
    }
  };

  const createGoogleCalendarUrl = () => {
    const startTime = moment
      .utc(editableEvent.start_time)
      .local()
      .format("YYYYMMDDTHHmmss");
    const endTime = moment
      .utc(editableEvent.end_time)
      .local()
      .format("YYYYMMDDTHHmmss");
    const details = encodeURIComponent(editableEvent.description);
    const title = encodeURIComponent(editableEvent.title);
    const timeZone = moment.tz.guess(); // Get the user's local timezone

    return `https://calendar.google.com/calendar/r/eventedit?text=${title}&dates=${startTime}/${endTime}&details=${details}&ctz=${encodeURIComponent(
      timeZone
    )}`;
  };

  return (
    <div className="ev-detail-overlay">
      <div className="ev-detail-dialog event-modal-dialog modal-lg">
        <div className="ev-detail-content event-modal-content">
          <div className="ev-detail-header">
            <h5 className="modal-title">
              {isEditMode ? "Edit Event" : "Event Details"}
            </h5>
            <button className="modal-close-x" onClick={onClose}>
              &times;
            </button>
          </div>
          <div className="modal-body">
            {event.image_url && (
              <img
                src={event.image_url}
                alt={event.title}
                className="event-image"
              />
            )}
            {/* Display Participant Count with Dynamic Styling */}
            {!isEditMode && (
              <>
                <p className={`participant-count ${participantColorClass}`}>
                  Participants: {currentEnrollments} / {maxCapacity}
                </p>
                {isFull && (
                  <p className="no-space-message">
                    No More Space Available in this Class
                  </p>
                )}
              </>
            )}
            {/* Editable form for event details */}
            {isEditMode ? (
              <>
                <input
                  type="text"
                  className="form-control mb-3"
                  name="title"
                  value={editableEvent.title}
                  onChange={handleInputChange}
                />
                <input
                  type="datetime-local"
                  className="form-control mb-3"
                  name="start_time"
                  value={moment
                    .utc(editableEvent.start_time)
                    .local()
                    .format("YYYY-MM-DDTHH:mm")}
                  onChange={handleInputChange}
                />
                <input
                  type="datetime-local"
                  className="form-control mb-3"
                  name="end_time"
                  value={moment
                    .utc(editableEvent.end_time)
                    .local()
                    .format("YYYY-MM-DDTHH:mm")}
                  onChange={handleInputChange}
                />
                <textarea
                  className="form-control mb-3"
                  name="description"
                  value={editableEvent.description}
                  onChange={handleInputChange}
                />
                <input
                  type="number"
                  className="form-control mb-3"
                  name="price"
                  value={editableEvent.price}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  className="form-control mb-3"
                  name="description_url"
                  value={editableEvent.description_url}
                  onChange={handleInputChange}
                />
              </>
            ) : (
              <>
                <h3>{event.title}</h3>
                <p>Start Time: {formatDateTimeForDisplay(event.start_time)}</p>
                <p>End Time: {formatDateTimeForDisplay(event.end_time)}</p>
                <p>Description: {event.description}</p>
                <p>Price: ${event.price}</p>
                {event.description_url && (
                  <a
                    href={event.description_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    More Info
                  </a>
                )}
              </>
            )}
          </div>
          <div className="modal-footer">
            {/* Only show Edit and Delete buttons to administrators */}
            {userRole &&
              userRole.toLowerCase() === "administrator" &&
              !displayOnly && (
                <>
                  {isEditMode ? (
                    <button className="btn btn-primary" onClick={handleSave}>
                      Save
                    </button>
                  ) : (
                    <>
                      <button
                        className="btn btn-secondary"
                        onClick={() => setIsEditMode(true)}
                      >
                        Edit
                      </button>
                      <button className="btn btn-danger" onClick={handleDelete}>
                        Delete
                      </button>
                    </>
                  )}
                </>
              )}
            {!isPastEvent && (
              <>
                <a
                  href={createGoogleCalendarUrl()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn btn-danger">Add to Calendar</button>
                </a>
                {/* Add to Cart Button */}
                <button
                  className={`btn btn-primary ${isFull ? "disabled-btn" : ""}`}
                  onClick={handleAddToCart}
                  disabled={isFull} // Disable if class is full
                  title={isFull ? "No more space available" : "Add to Cart"}
                >
                  {isFull ? "Class Full" : "Add to Cart"}
                </button>
              </>
            )}
            <button className="btn btn-secondary" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetailsPopup;
